import React, { Component } from 'react';
import autobind from 'react-autobind';
import ENMessage from '../../i18n/en';
import ZHMessage from '../../i18n/zh';
import { storageManager, STORAGE_TYPES } from '../../utils/storageUtils';

const currentLanguage = storageManager.createOrFetchStorage('currentLanguage', STORAGE_TYPES.local);

const context = React.createContext({});

const LocaleProvider = context.Provider;
const LocaleConsumer = context.Consumer;

class LocaleContext extends Component {
  constructor() {
    super();

    if (!currentLanguage.get() && window.navigator.language.includes('en')) {
      currentLanguage.set('en');
    } else if (!currentLanguage.get()) {
      currentLanguage.set('zh');
    }

    this.state = {
      currentLocale: currentLanguage.get(),
      nextLocale: currentLanguage.get() === 'en' ? 'zh' : 'en',
      messages: currentLanguage.get() === 'en' ? ENMessage : ZHMessage
    };

    autobind(this);
  }

  toggleLanguage() {
    if (this.state.currentLocale === 'en') {
      this.setState({
        currentLocale: 'zh',
        nextLocale: 'en',
        messages: ZHMessage
      }, () => {
        currentLanguage.set('zh');
      });
    } else {
      this.setState({
        currentLocale: 'en',
        nextLocale: 'zh',
        messages: ENMessage
      }, () => {
        currentLanguage.set('en');
      });
    }
  }
  
  render() {
    const { currentLocale, nextLocale, messages } = this.state;
    return (
      <LocaleProvider
        value={{
          currentLocale,
          nextLocale, 
          messages: messages,
          toggleLanguage: this.toggleLanguage
        }}
      >
        {this.props.children}
      </LocaleProvider>
    );
  }
}

export {
  LocaleContext as LocaleProvider,
  LocaleConsumer
};