const videoPlayerStyles = theme => ({
  hiddenPlayerComponents: {
    display: 'none !important'
  },
  textContentContainer: {
    position: 'relative'
  },
  textContentContainerHover: {
    transform: 'scale(1.2)',
  },
  textWrapper: {
    transition: 'all 1s ease',
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  textWrapperHover: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0)',
    }
  },
  videoCoverText: {
    opacity: '0.8',
    color: theme.palette.primary.contrastText
  },
  videoCoverTextHover: {
    opacity: '1',
  },
  workLinkText: {
    fontWeight: 'bold',
  }
});

export default videoPlayerStyles;