import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import autobind from 'react-autobind';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '../../../components/layout/grid';
import formStyles from '../../../assets/jss/formStyles';
import Button from '../../../components/button/button';
import { validateEmail, validateNonEmpty } from '../../../utils/helpers';

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      name: {
        error: null,
        value: ''
      },
      company: {
        error: null,
        value: ''
      },
      email: {
        error: null,
        value: ''
      },
      phone: {
        error: null,
        value: ''
      },
      message: {
        error: null,
        value: ''
      }
    };
    autobind(this);
  }

  formFields = ['name', 'company', 'email', 'phone', 'message'];

  handleInputChange(event, value) {
    const fieldName = typeof event !== 'string' ? event.target.name : event;
    const fieldValue = typeof event !== 'string' ? event.target.value : value
    this.setState(() => {
      return {
        [fieldName]: {
          error: this.validate(fieldName, fieldValue),
          value: fieldValue
        }
      };
    });
  }

  validate(fieldName, value) {
    switch(fieldName) {
    case 'email':
      if (!validateNonEmpty(value)) {
        return 0;
      } else if (!validateEmail(value)) {
        return 1;
      }
      return null;
    case 'name':
    case 'message':
      return validateNonEmpty(value) ? null : 0;
    default:
      return null;
    }
  }

  getErrorMesssages(errorCode) {
    switch (errorCode) {
    case 0:
      return <FormattedMessage id="contact.contactForm.nonEmptyErrorMessage" />;
    case 1:
      return <FormattedMessage id="contact.contactForm.wrongEmailFormatErrorMessage" />;
    default:
      return null;
    }
  }

  isFormValid() {
    const errorCount = this.formFields.reduce((acc, cur) => {
      if (this.state[cur].error !== null) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    return errorCount === 0;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.formFields.forEach((field) => {
      this.handleInputChange(field, this.state[field].value);
    });
    // Hack to handle initial check since set state is async
    setTimeout(() => {
      if (this.isFormValid()) {
        console.log('valid');
      } else {
        console.log('error');
      }
    });

  }

  render() {
    const { name, company, email, phone, message } = this.state;
    const { classes } = this.props;
    return (
      <div className="contact-form">
        <Paper square style={{padding: '0px 20px'}}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left">
                <FormattedMessage id="contact.contactForm.title" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={this.handleSubmit}>
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={name.error !== null}
                      className={classes.textInput}
                      id="name"
                      label={<FormattedMessage id="contact.contactForm.nameField" />}
                      name="name"
                      value={name.value}
                      onChange={this.handleInputChange}
                      helperText={this.getErrorMesssages(name.error)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.textInput}
                      id="company"
                      label={<FormattedMessage id="contact.contactForm.companyField" />}
                      name="company"
                      value={company.value}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={email.error !== null}
                      className={classes.textInput}
                      id="email"
                      label={<FormattedMessage id="contact.contactForm.emailField" />}
                      name="email"
                      value={email.value}
                      onChange={this.handleInputChange}
                      helperText={this.getErrorMesssages(email.error)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.textInput}
                      id="phone"
                      label={<FormattedMessage id="contact.contactForm.phoneField" />}
                      name="phone"
                      value={phone.value}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={message.error !== null}
                      className={classes.fullWidthInput}
                      multiline
                      id="message"
                      label={<FormattedMessage id="contact.contactForm.messageField" />}
                      name="message"
                      value={message.value}
                      onChange={this.handleInputChange}
                      helperText={this.getErrorMesssages(message.error)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" color="primary" fullWidth>
                      <FormattedMessage id="contact.contactForm.submitButton" />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(formStyles)(ContactForm);
