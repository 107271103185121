import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typogrphy from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import HeroWrapper from '../../components/hero/heroWrapper';
import SectionContainer from '../../components/shared/sectionContainer';
import homeHeroImage from '../../assets/images/hero-placeholder.jpg';
import typographyStyles from '../../assets/jss/typographyStyles';

const SectionHero = ({classes, heroImage, heroText}) => {
  return (
    <HeroWrapper filter image={heroImage} shorter>
      <SectionContainer zIndex2>
        <Typogrphy variant="h1" className={`${classes.primaryContrast} ${classes.bold} ${classes.highOpacity} ${classes.zIndex2}`}>
          <FormattedMessage id={heroText} />
        </Typogrphy>
      </SectionContainer>
    </HeroWrapper>
  );
};

export default withStyles(typographyStyles)(SectionHero);
