import React, { Component, Fragment } from 'react';
import TagCloud from '../../assets/lib/TagCloud';
import randomColor from 'randomcolor';
import { FormattedMessage } from 'react-intl';
import Typogrphy from '@material-ui/core/Typography';
import Fade from 'react-reveal/Fade';
import withSizes from 'react-sizes';
import { mapSizesToProps } from '../shared/screenSzies'; 

class WordCloud extends Component {

  render() {
    const { words, isMobile } = this.props;
  
    return (
      <TagCloud 
        className='tag-cloud'
        // rotate={() => Math.round(Math.random()) * 15}
        style={{
          fontFamily: 'sans-serif',
          fontSize: () => Math.round(Math.random() * (isMobile ? 10 : 15)) + (isMobile ? 10 : 16),
          // fontSize: 30,
          color: () => randomColor({
            hue: 'blue'
          }),
          padding: 5,
          flex: 1,
        }}>
        {
          words.map((word, index) => {
            return (
              <div key={index}>
                <Fade delay={index * 300} duration={1000}>
                  {word}
                </Fade>
              </div>
            );
          })
        }
      </TagCloud>
    );
  }
}

export default withSizes(mapSizesToProps)(WordCloud);
