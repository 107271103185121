import React, { Component, Fragment } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import GeneralContentSection from '../../components/works/generalContentSection';
import Roles from '../../components/works/roles';
import ProjectHighlight from '../../components/works/projectHighlight';
import housingPortfolioHeroImage from '../../assets/images/hero_housing_portfolio.jpg';
import computerExcelImage from '../../assets/images/computer_excel.jpg';

class HousingPorfolio extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#FFF'}}>
        <SectionHero heroImage={housingPortfolioHeroImage} heroText="housingPortfolio.heroText"></SectionHero>
        <GeneralContentSection
          isHeader
          heading="housingPortfolio.clientProfile.heading"
          details="housingPortfolio.clientProfile.details"
        />
        <Roles
          platforms={['works.roles.excelVBA']}
          roles={['works.roles.solutionDesign', 'works.roles.appDevelopment']}
          deliverables={['works.roles.completeApplication', 'works.roles.dataModel']}
        />
        <ProjectHighlight
          content="housingPortfolio.projectHighlight.content"
          image={computerExcelImage}
        />
        <GeneralContentSection
          white
          heading="housingPortfolio.section1.heading"
          details="housingPortfolio.section1.details"
        />
        <GeneralContentSection
          white
          vertical
          heading="housingPortfolio.section2.heading"
          details="housingPortfolio.section2.details"
        />
        <GeneralContentSection
          white
          heading="housingPortfolio.section3.heading"
          details="housingPortfolio.section3.details"
        />
      </div>
    );
  }
}

export default HousingPorfolio;