import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Typogrphy from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../shared/sectionContainer';
import Grid from '../layout/grid';
import Divider from '../layout/divider';
import typographyStyles from '../../assets/jss/typographyStyles';

class AppFooter extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="app-footer">
        <SectionContainer wide>
          <Grid
            container
            spacing={16}
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '20px' }}
          >
            <Grid item xs={12} md={4}>
              <Grid container spacing={16} alignItems="center">
                {/* <Grid item xs={2}>
                  <StarOutlinedIcon color="primary"></StarOutlinedIcon>
                </Grid> */}
                <Grid item xs={10}>
                  <Typogrphy variant="h6" className={classes.primaryContrast}>
                    <FormattedMessage id="appFooter.companyMessage.heading" />
                  </Typogrphy>
                  <Typogrphy
                    variant="body2"
                    className={classes.primaryContrast}
                    align="justify"
                  >
                    <FormattedMessage id="appFooter.companyMessage.bodyContent" />
                  </Typogrphy>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justify="space-between" spacing={8}>
                <Grid item xs={6} md={2}>
                  <Link to="/services" style={{ textDecorationColor: 'white' }}>
                    <Typogrphy
                      variant="body2"
                      className={classes.primaryContrast}
                      align="center"
                    >
                      <FormattedMessage id="appFooter.companyMessage.footerLink1" />
                    </Typogrphy>
                  </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Link to="/works" style={{ textDecorationColor: 'white' }}>
                    <Typogrphy
                      variant="body2"
                      className={classes.primaryContrast}
                      align="center"
                    >
                      <FormattedMessage id="appFooter.companyMessage.footerLink2" />
                    </Typogrphy>
                  </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Link to="/about" style={{ textDecorationColor: 'white' }}>
                    <Typogrphy
                      variant="body2"
                      className={classes.primaryContrast}
                      align="center"
                    >
                      <FormattedMessage id="appFooter.companyMessage.footerLink3" />
                    </Typogrphy>
                  </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Link to="/careers" style={{ textDecorationColor: 'white' }}>
                    <Typogrphy
                      variant="body2"
                      className={classes.primaryContrast}
                      align="center"
                    >
                      <FormattedMessage id="appFooter.companyMessage.footerLink5" />
                    </Typogrphy>
                  </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Link to="/contact" style={{ textDecorationColor: 'white' }}>
                    <Typogrphy
                      variant="body2"
                      className={classes.primaryContrast}
                      align="center"
                    >
                      <FormattedMessage id="appFooter.companyMessage.footerLink4" />
                    </Typogrphy>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider light gutters />
          <Grid container style={{ paddingTop: '20px' }}>
            <Grid item>
              <Typogrphy variant="caption" className={classes.primaryContrast}>
                <FormattedMessage id="appFooter.disclosure" />
              </Typogrphy>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default withStyles(typographyStyles)(AppFooter);
