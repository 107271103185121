import React, { Component } from 'react';
import ServiceIntro from '../../components/service/serviceIntroWrapper';
import ServiceAreaDetails from '../../components/service/serviceAreaDetailsWrapper';
import SectionHero from '../../components/sectionHero/sectionHero';
import tableauHeroImage from '../../assets/images/hero_tableau.png';
import tableauImage1 from '../../assets/images/tableau_reporting.svg';
import tableauImage2 from '../../assets/images/tableau_book.svg';
import tableauImage3 from '../../assets/images/tableau_data.svg';
import tableauImage4 from '../../assets/images/tableau_cloud.svg';


class TableauServices extends Component {
  render() {
    return (
      <div>
        <SectionHero heroImage={tableauHeroImage} heroText="tableau.heroText"></SectionHero>
        <ServiceIntro
          title="tableau.intro.title"
          heading="tableau.intro.heading"
          serviceArea1Title="tableau.intro.serviceArea1Title"
          serviceArea1Content="tableau.intro.serviceArea1Content"
          serviceArea2Title="tableau.intro.serviceArea2Title"
          serviceArea2Content="tableau.intro.serviceArea2Content"
          serviceArea3Title="tableau.intro.serviceArea3Title"
          serviceArea3Content="tableau.intro.serviceArea3Content"
          serviceArea4Title="tableau.intro.serviceArea4Title"
          serviceArea4Content="tableau.intro.serviceArea4Content"
        />
        <ServiceAreaDetails
          image={tableauImage1}
          title="tableau.reporting.title"
          prefixText="tableau.reporting.prefix"
          content="tableau.reporting.content"
        />
        <ServiceAreaDetails
          imageRight
          image={tableauImage2}
          title="tableau.dashboard.title"
          prefixText="tableau.dashboard.prefix"
          content="tableau.dashboard.content"
        />
        <ServiceAreaDetails
          image={tableauImage3}
          title="tableau.modeling.title"
          prefixText="tableau.modeling.prefix"
          content="tableau.modeling.content"
        />
        <ServiceAreaDetails
          imageRight
          image={tableauImage4}
          title="tableau.analytics.title"
          prefixText="tableau.analytics.prefix"
          content="tableau.analytics.content"
        />
      </div>
    );
  }
}

export default TableauServices;
