import React, { Component } from 'react';
import Grid from '../../../components/layout/grid';
import Typogrphy from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../../components/shared/sectionContainer';
import Fade from 'react-reveal/Fade';
import {injectIntl} from 'react-intl';

class Intro extends Component {
  state = {
    headingMessage: '',
    mainContentMessage: ''
  };

  componentDidMount() {
    const { intl } = this.props;
    const translatedHeadingMessage = intl.formatMessage({
      id: 'home.intro.byAuthor'
    });
    const translatedMainContentMessage = intl.formatMessage({
      id: 'home.intro.mainContent'
    });
    this.setState({
      headingMessage: translatedHeadingMessage,
      mainContentMessage: translatedMainContentMessage
    });
  }

  render() {
    const {
      headingMessage,
      mainContentMessage
    } = this.state;

    return (
      <div className="intro">
        <SectionContainer>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typogrphy variant="h4" align="center">
                <Fade duration={2000}>{mainContentMessage}</Fade>
              </Typogrphy>
            </Grid>
            <Grid item xs={12}>
              <Typogrphy variant="h5" align="center">
                <Fade duration={2000} delay={500}>{headingMessage}</Fade>
              </Typogrphy>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default injectIntl(Intro);
