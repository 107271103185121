import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import ReactHoverObserver from 'react-hover-observer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '../../../components/layout/grid';
import SectionContainer from '../../../components/shared/sectionContainer';
import VideoPlayer from '../../../components/shared/videoPlayer';
import dataImage from '../../../assets/images/data.jpg';
import housingPlatformImage from '../../../assets/images/housing_platform.jpg';
import realtimeMonitorImage from '../../../assets/images/realtime_monitor.jpg';
import tableauImage from '../../../assets/images/tableau.png';
import excelImage from '../../../assets/images/excel.jpeg';
import videoPlayerStyles from '../../../assets/jss/videoPlayerStyles';
import { FormattedMessage } from 'react-intl';
import ImageWrapper from '../../../components/shared/imageWrapper';

const WorkLink = withStyles(videoPlayerStyles)(({ classes, link, coverText, poster }) => {
  return (
    <Link to={link}>
      {/* <ReactHoverObserver>
        {
          ({isHovering}) => {
            return (
              <Paper style={{overflow: 'hidden'}}>
                <VideoPlayer
                  isHovering={isHovering}
                  poster={poster}
                  src="https://media.istockphoto.com/videos/aerial-view-of-couple-stand-up-paddling-video-id905751012"
                  hoverEffect
                  coverText={
                    <Typography variant="h5" align="center">
                      LINK 1
                    </Typography>
                  }
                />
              </Paper>
            );
          }
        }
      </ReactHoverObserver> */}
      <ReactHoverObserver>
        {
          ({isHovering}) => {
            return (
              <Paper style={{overflow: 'hidden'}}>
                <Grid container>
                  <Grid className={classes.textContentContainer} item xs={12}>
                    <ImageWrapper src={poster} height="100%" className={ isHovering ? `${classes.textContentContainerHover}` : ''}></ImageWrapper>
                    <Grid className={classes.textWrapper} container justify="center" alignItems="center" fullHeight>
                      <Grid item xs={8}>
                        <Typography className={`${classes.videoCoverText} ${classes.workLinkText}`} variant="h4" align="center">
                          <FormattedMessage id={coverText} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            );
          }
        }
      </ReactHoverObserver>
    </Link>
  );
});

const WorkLinks = () => {
  return (
    <div className="work-links">
      <SectionContainer noPadTop>
        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <WorkLink link="/works/tableau-dashboard" poster={tableauImage} coverText="works.projectLink.project1" />
          </Grid>
          <Grid item xs={12} md={6}>
            <WorkLink link="/works/realtime-monitor" poster={realtimeMonitorImage} coverText="works.projectLink.project2"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <WorkLink link="/works/housing-portfolio-management" poster={excelImage} coverText="works.projectLink.project3"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <WorkLink link="/works/centralized-analytic-platform" poster={housingPlatformImage} coverText="works.projectLink.project4"/>
          </Grid>
        </Grid>
      </SectionContainer>
    </div>
  );
};

export default WorkLinks;