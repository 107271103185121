import React, { Component } from 'react';
import ServiceIntro from '../../components/service/serviceIntroWrapper';
import ServiceAreaDetails from '../../components/service/serviceAreaDetailsWrapper';
import SectionHero from '../../components/sectionHero/sectionHero';
import AIPossibilities from './aiPossibilities';
import iotHeroImage from '../../assets/images/hero_iot.jpg';
import iotAppImage from '../../assets/images/services/iot_application.png';
import iorAiImage from '../../assets/images/services/iot_ai.png';
import ioRoboticsImage from '../../assets/images/services/iot_robotics.png';

class IoTSolution extends Component {
  render() {
    return (
      <div>
        <SectionHero heroImage={iotHeroImage} heroText="iot.heroText"></SectionHero>
        <ServiceIntro
          title="iot.intro.title"
          heading="iot.intro.heading"
          serviceArea1Title="iot.intro.serviceArea1Title"
          serviceArea1Content="iot.intro.serviceArea1Content"
          serviceArea2Title="iot.intro.serviceArea2Title"
          serviceArea2Content="iot.intro.serviceArea2Content"
          serviceArea3Title="iot.intro.serviceArea3Title"
          serviceArea3Content="iot.intro.serviceArea3Content"
          serviceArea4Title="iot.intro.serviceArea4Title"
          serviceArea4Content="iot.intro.serviceArea4Content"
        />
        <ServiceAreaDetails
          image={iotAppImage}
          title="iot.application.title"
          prefixText="iot.application.prefix"
          content="iot.application.content"
        />
        <ServiceAreaDetails
          imageRight
          image={iorAiImage}
          title="iot.businessInsights.title"
          prefixText="iot.businessInsights.prefix"
          content="iot.businessInsights.content"
        />
        <ServiceAreaDetails
          image={ioRoboticsImage}
          title="iot.automation.title"
          prefixText="iot.automation.prefix"
          content="iot.automation.content"
        />
        <AIPossibilities />
      </div>
    );
  }
}

export default IoTSolution;
