import React, { Component } from "react";
import { Router, Route, withRouter, Redirect, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import Headroom from "react-headroom";
import Home from "./pages/home/homePage";
import ScrollToTop from "./components/shared/scrollToTop";
import OurServices from "./pages/services/ourServices";
import IoTSolution from "./pages/iotSolution/iotSolution";
import BusinessInsight from "./pages/businessInsight/businessInsight";
import TableauServices from "./pages/tableau/tableauServices";
import OurWorks from "./pages/ourWorks/ourWorks";
import TableauDashboard from "./pages/works/tableauDashboard";
import HousingPortfolio from "./pages/works/housingPortfolio";
import HousingPlatform from "./pages/works/housingPlatform";
import RealtimeMonitor from "./pages/works/realtimeMonitor";
import Insights from "./pages/insights/insights";
import AboutUs from "./pages/about/aboutUs";
import ContactUs from "./pages/contact/contactUs";
import Careers from "./pages/careers/careers";
import BI from "./pages/careers/positions/bi";
import InternConsultantEnergy from "./pages/careers/positions/internConsultantEnergy";
import InternConsultantPublic from "./pages/careers/positions/internConsultantPublic";
import Header from "./components/header/header";
import HeaderLinks from "./components/header/headerLinks";
import Footer from "./components/footer/footer";

const history = createBrowserHistory();

const HeaderComponent = withRouter(({ location, currentPath }) => {
  const pathname = currentPath === "" ? location.pathname : currentPath;
  return (
    <Header
      color="transparent"
      brand="VS"
      rightLinks={<HeaderLinks />}
      changeColorOnScroll={{
        height: pathname === "/" ? 480 : 250,
        color: "white",
      }}
    />
  );
});

class Layout extends Component {
  state = {
    currentPath: "",
  };

  historyListener = null;

  componentDidMount() {
    this.historyListener = history.listen((location, action) => {
      this.setState({
        currentPath: location.pathname,
      });
    });
  }

  componentWillUnmount() {
    this.historyListener();
  }

  render() {
    const { currentPath } = this.state;
    return (
      <Router history={history}>
        <ScrollToTop>
          <div id="main">
            <Headroom
              wrapperStyle={{
                zIndex: 1100,
                position: "fixed",
                width: "100%",
              }}
              style={{
                WebkitTransition: "all .5s ease-in-out",
                MozTransition: "all .5s ease-in-out",
                OTransition: "all .5s ease-in-out",
                transition: "all .5s ease-in-out",
              }}
            >
              <HeaderComponent currentPath={currentPath} />
            </Headroom>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/services" component={IoTSolution} />
              <Route exact path="/services/iot" component={IoTSolution} />
              <Route exact path="/services/bi" component={BusinessInsight} />
              <Route
                exact
                path="/services/tableau"
                component={TableauServices}
              />
              <Route exact path="/works" component={OurWorks} />
              <Route
                exact
                path="/works/tableau-dashboard"
                component={TableauDashboard}
              />
              <Route
                exact
                path="/works/housing-portfolio-management"
                component={HousingPortfolio}
              />
              <Route
                exact
                path="/works/centralized-analytic-platform"
                component={HousingPlatform}
              />
              <Route
                exact
                path="/works/realtime-monitor"
                component={RealtimeMonitor}
              />
              {/* <Route exact path="/insights" component={Insights} /> */}
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/careers" component={Careers} />
              <Route exact path="/careers/bi" component={BI} />
              <Route
                exact
                path="/careers/intern-consultant-energy"
                component={InternConsultantEnergy}
              />
              <Route
                exact
                path="/careers/intern-consultant-public"
                component={InternConsultantPublic}
              />
              <Route exact path="/contact" component={ContactUs} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
            <Footer></Footer>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default Layout;
