import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import videoPlayerStyles from '../../assets/jss/videoPlayerStyles';

class ImageWrapper extends Component {
  render() {
    const { alt, src, width, height, white, noMargin, ...props } = this.props;
    const imageProps = {
      width: width || '100%',
      ...(height ? {height: height} : null),
    };
    const styleProps = {
      transition: 'all 1s ease',
      ...(!!noMargin ? null : {marginLeft: 'auto'}),
      ...(!!noMargin ? null : {marginRight: 'auto'})
    };
    const containerStyles = {
      display: 'inline-block',
      ...(white ? {backgroundColor: '#FFF'} : null),
    };

    return (
      <img
        src={src}
        alt={alt}
        {...imageProps}
        {...props}
        style={styleProps}
      />
    );
  }
}

export default withStyles(videoPlayerStyles)(ImageWrapper);
