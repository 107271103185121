const messages = {
  // App Header
  "appHeader.languageButton.en": "EN",
  "appHeader.languageButton.zh": "中",
  "appHeader.navLink.ourWork": "Our Work",
  "appHeader.navLink.service": "Service",
  "appHeader.navLink.insights": "Insights",
  "appHeader.navLink.about": "About",
  "appHeader.navLink.contact": "Contact Us",
  "appHeader.subNavLink.iot": "IoT Services",
  "appHeader.subNavLink.bi": "Business Insight",
  "appHeader.subNavLink.tableau": "Tableau Services",
  "appHeader.subNavLink.tableauDashboard": "Tableau Dashboard",
  "appHeader.subNavLink.realtimeMonitor": "Realtime Monitoring Platform ",
  "appHeader.subNavLink.housingPortfolio": "Housing Portfolio Management",
  "appHeader.subNavLink.housingPlatform": "Centralized Analytic Platform",
  // App Footer
  "appFooter.companyMessage.heading": "VS Consulting",
  "appFooter.companyMessage.bodyContent":
    "We would like to walk together with all of you towards the era of data",
  "appFooter.companyMessage.footerLink1": "Our Services",
  "appFooter.companyMessage.footerLink2": "Our Works",
  "appFooter.companyMessage.footerLink3": "About Us",
  "appFooter.companyMessage.footerLink4": "Contact Us",
  "appFooter.companyMessage.footerLink5": "Careers",
  "appFooter.disclosure": "© 2019 VS Consulting. All Rights Reserved.",
  // Home Page
  "home.heroText1": "VS Consulting",
  "home.heroText2": "Let us help to prepare your business for tomorrow",
  "home.heroText3": "VS HOME - 3",
  "home.intro.byAuthor": "- Daniel Keys Moran",
  "home.intro.mainContent":
    '"You can have data without information, but you cannot have information without data."',
  "home.ourServices.prefixText": "WHO WE ARE",
  "home.ourServices.heading": "Data is in our gene",
  "home.ourServices.mainContent":
    "We help our clients identify and solve their unique business problems by leveraging our data-driven approaches to develop cutting-edge and tailored solution.",
  "home.ourServices.ctaButton": "Our services",
  "home.ourWorks.prefixText": "OUR PROJECTS",
  "home.ourWorks.heading": "Discover what's possible",
  "home.ourWorks.mainContent":
    "We have successfully made huge positive impacts to our clients across a range of industries.",
  "home.ourWorks.ctaButton": "Our works",
  "home.ourWorks.wordCloud.item1": "Data",
  "home.ourWorks.wordCloud.item2": "Artificial Intelligence",
  "home.ourWorks.wordCloud.item3": "Big Data",
  "home.ourWorks.wordCloud.item4": "Tableau",
  "home.ourWorks.wordCloud.item5": "Machine Learning",
  "home.ourWorks.wordCloud.item6": "Enterprise Education",
  "home.ourWorks.wordCloud.item7": "Consulting",
  "home.ourWorks.wordCloud.item8": "Analytic Center",
  "home.ourWorks.wordCloud.item9": "IoT",
  "home.ourWorks.wordCloud.item10": "Data Warehouse",
  "home.ourWorks.wordCloud.item11": "Web Application",
  "home.ourWorks.wordCloud.item12": "Blockchain",
  "home.ourWorks.wordCloud.item13": "Data Science",
  "home.ourWorks.wordCloud.item14": "Tableau Dashboard",
  "home.ourWorks.wordCloud.item15": "Predictive Analysis",
  "home.ourWorks.wordCloud.item16": "Cloud Solution",
  "home.ourWorks.wordCloud.item17": "Digital Transformation",
  "home.ourWorkflow.header": "Providing end to end solutions",
  "home.ourWorkflow.consultancy.header": "Consultancy",
  "home.ourWorkflow.consultancy.body":
    "The Filament Strategy team are genuine thought-leaders in the field. We are differentiated by our practical experience of delivering AI centred projects for our clients.",
  "home.ourWorkflow.implementation.header": "Implementation",
  "home.ourWorkflow.implementation.body":
    "The core capability, Filament brings two decades of enterprise application design & build. Design-led & agile, we delight our clients and retain strong relationships.",
  "home.ourWorkflow.education.header": "Education",
  "home.ourWorkflow.education.body":
    "Filament have created a suite of educational programmes & resources to develop the in-house capability of our clients, launching them as an AI leader in their industry.",
  "home.ourWorkflow.content":
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit",
  // Service Page
  "services.heroText1": "Our Services",
  "services.intro.heading": "Lorem ipsum dolor sit amet",
  "services.intro.body":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus augue orci, tincidunt ac lobortis vitae, posuere id odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer tristique eros sit amet nisi placerat eleifend. Donec imperdiet mi id orci hendrerit tincidunt fringilla ut leo. Suspendisse sollicitudin interdum dui, ac dictum mauris eleifend vel. Nunc nec lorem accumsan, mattis nulla eget, ullamcorper erat.",
  // IoT Solution Page
  "iot.heroText": "AI & IoT Solution",
  "iot.intro.title":
    "Embrace the future of AI and IoT, you will be amazed by what AI and IoT can do for your organization",
  "iot.intro.heading":
    "With IoT and AI technology, we are able to help your organization to connect to the Digital world and open up a new set of opportunities for your organization not only in the day to day business but also potentials to expand the market from the perspective you never imagine before. The amount of data generated the connected devices will be the perfect complementary feed to our applications embedded with AI model. The potential is limitless.",
  "iot.intro.serviceArea1Title": "IoT application",
  "iot.intro.serviceArea1Content":
    "Seemingly collects raw data from sensors across the field, allows customers to monitor data, auto alarm anomalies and provide insights in real time",
  "iot.intro.serviceArea2Title": "AI to insight",
  "iot.intro.serviceArea2Content":
    "Use targeted AI algorithms to help you to understand your business better by extracting hidden insights from ocean amount of structured or unstructured data",
  "iot.intro.serviceArea3Title": "Robotic process automation",
  "iot.intro.serviceArea3Content":
    "Augmented by AI and Machine Learning technology, the bots can cognitively help you to automate your business process",
  "iot.intro.serviceArea4Title": "Possibilities",
  "iot.intro.serviceArea4Content":
    "The potential of AI and IoT are beyond our imagination. We would love to hear your unique business problems and tackle them with you together.",
  "iot.application.title": "Truly customized for your business",
  "iot.application.prefix": "IoT APPLICATION",
  "iot.application.content":
    "We’d love to help you to strategize the IoT capability and potential then develop the Iot Application that tailored to your special business environment. Through well-designed IoT data pipeline, structured information from sensor such as as temperature, pressure, humidity, air quality and unstructured information such as video clip, photo, voice, etc. can be collected and monitored real time. What’s more, AI technology also brings the ability to automatically identify patterns, detect anomalies and eventually drives clear action for the business.",
  "iot.businessInsights.title": "Get to know what you didn’t know",
  "iot.businessInsights.prefix": "AI TO INSIGHT",
  "iot.businessInsights.content":
    "Utilizing AI technology, we dive deep into your core business problem and create the potential to provide business insights previously unavailable and to create new business value through data-driven decisions, for example, assessing how customer preferences are changing through time, evaluating why customers leave or prefer certain and predicting the future value (or optimizing the current value) of an existing product, etc.",
  "iot.automation.title": "It’s not replacing us but advancing us",
  "iot.automation.prefix": "ROBOTIC PROCESS AUTOMATION",
  "iot.automation.content":
    "Combining our profound knowledge across different industries and advanced AI and Machine learning skill, we are able to help your organization to drive better efficiency, to reduce cost and to prevent manual mistakes in the day to day business operations, for example, An automated paper work reviewing application can drive key information and clauses from countless pages of documents which can take hours manually, by utilizing AI to extract the key information and use human eyes to just oversee the process, this will largely enhance the process speed and accuracy than the original, human-only process.",
  "iot.possibilities.title":
    "The possibilities with AI technology is limitless",
  "iot.possibilities.prefix": "LIMILESS POSSIBILITIES",
  "iot.possibilities.content":
    "The potential of AI and IoT are beyond our imagination. We would love to hear your unique business problems and tackle them with you together.",
  // Business Insights Page
  "bi.heroText": "Business Insight",
  "bi.intro.title":
    "Actionable insight derived from data is the key to drive your business growth.",
  "bi.intro.heading":
    "From data to insight, and insight to actions are both essential parts to drive business value. We can help you streamline your data management process, derive business insight from your data asset, and drive business outcome from actionable insight.",
  "bi.intro.serviceArea1Title": "Enterprise Business Intelligence Platform",
  "bi.intro.serviceArea1Content":
    "Building solid foundation to ensure the data availability and accessibility to operate your operational business activities.",
  "bi.intro.serviceArea2Title": "Executive Dashboard and Analytics",
  "bi.intro.serviceArea2Content":
    "Empowering your leaders with a 360 degree view of business performance and insight of your organization",
  "bi.intro.serviceArea3Title": "Advanced Analytics",
  "bi.intro.serviceArea3Content":
    "Ensuring discovery of business drivers and enabling insight driven business strategy",
  "bi.intro.serviceArea4Title": "Predictive Analytics",
  "bi.intro.serviceArea4Content":
    "Securing competitive edge by proactive actions with future market and business trending insight.",
  "bi.platform.title": "AI not ready yet? Get your BI ready first!",
  "bi.platform.prefix": "ENTERPRISE BUSINESS INELLIGENCE PLATFORM",
  "bi.platform.content":
    "To build an enterprise data warehouse and BI platform is not just data integration and reporting implementation, it also requires business process integration, data management strategy and transformation management. To make it successful, we help our clients to align IT/technology consideration with business priorities first, design and agree on overall enterprise BI strategy, then translate strategy to tactical action items. We have expertise of mainstream tools and applications, including but not limited to: Database (SAP HANA, Teradata, Oracle, SQL Server, DB2 etc), ETL(Informatica, DataStage etc), Metadata management, Master data management, Reporting (Cognos, BOBJ, Tableau, PowerBI etc).",
  "bi.dashboard.title": "Everything is at your fingertips",
  "bi.dashboard.prefix": "EXECTIVE DASHBOARD AND ANALYTICS",
  "bi.dashboard.content":
    "Executive dashboard provides a quick and easy way for executives and leaders to view company’s performance in real time and provides direction for the next action for the business. We can help you build your customized and streamline dashboard that not only provides visibility and insights, but also help you build a performance driven culture within your company with transparent performance presentation.",
  "bi.advanced.title": "Never too difficult to find a clue",
  "bi.advanced.prefix": "ADVANCED ANALYTICS",
  "bi.advanced.content":
    "Advanced analytics can help you uncover business insight much faster and more accurate. By leveraging technology to build precise models, your company will have a better chance of identifying profitable opportunities – or avoiding unknown risks. Advanced analytic techniques include data/text mining, pattern matching, machine learning, forecasting, visualization, semantic analysis, network and cluster analysis, simulation etc.",
  "bi.predictive.title": "Knowing now is far from enough",
  "bi.predictive.prefix": "PREDICTIVE ANALYTICS",
  "bi.predictive.content":
    "Predictive analytics can help you get a best assessment of what will happen in the future. By leveraging technology including machine-learning and data-mining techniques, you can uncover hidden patterns and trends in data that are critical for your company to define short and long term strategy, and help fulfill critical business needs.",
  // Tableau Service Page
  "tableau.heroText": "Tableau Services",
  "tableau.intro.title":
    "With Tableau, we help you and your organization understand your data easier and better",
  "tableau.intro.heading":
    "No matter what IT infrastructure your organization is currently deploying, VS is always your reliable and trustworthy source of Tableau expertise. With the deep understanding in Tableau suite and the related products, combining with our profound experience with various industries, we are here to help you to push your business forward with the tailored package of Tableau solution.",
  "tableau.intro.serviceArea1Title": "Enterprise Reporting & Analytic Platform",
  "tableau.intro.serviceArea1Content":
    "Centralize and consolidate all the scattered source of information into a single source platform.",
  "tableau.intro.serviceArea2Title": "Dashboard Development",
  "tableau.intro.serviceArea2Content":
    "Effective Visualization provide you the ability to oversee and react to your business performance in seconds.",
  "tableau.intro.serviceArea3Title": "Data Modeling and Transformation",
  "tableau.intro.serviceArea3Content":
    "A well designed data pipeline ensures the data is ready for analysis for your dashboard with optimal performance.",
  "tableau.intro.serviceArea4Title": "Advanced Analytics with Tableau",
  "tableau.intro.serviceArea4Content":
    "Equipping your Tableau dashboard with Advanced Analytics elements to provide a complete picture.",
  "tableau.reporting.title": "Not only a Dashboard but a complete solution",
  "tableau.reporting.prefix": "ENTERPRISE REPORTING & ANALYTIC PLATFORM",
  "tableau.reporting.content":
    "We are specialized in enterprise and application data platform solution with built-in features such as user-self serve reporting, business scorecard, advanced analytics module, etc. and be able to combine a number of dashboards and components into one streamline application hosted by Tableau Server. Such application can provide a lot of benefits to enterprise, such as providing timely information to all the business users, eliminating a lot of “black market” reports that floating around the organization and being able to provide a single source of truth to the whole organization.",
  "tableau.dashboard.title": "Tell your story with a dashboard",
  "tableau.dashboard.prefix": "DASHBOARD DEVELOPMENT",
  "tableau.dashboard.content":
    "When it comes to visualization, Tableau provides a complete set of forms of visualization including maps, gauges, charts, cross-tables and drill down, etc. Our approaches of creating an effective dashboard for your organization is to put solution and action as priority, we listen to your requirement and extract the logic flow behind your unique business and then utilize our data visualization and analytics skill to develop the exact dashboard that help you to provide the next action.",
  "tableau.modeling.title": "Garbage in, garbage out!",
  "tableau.modeling.prefix": "DATA MODELING AND TRANSFORMATION",
  "tableau.modeling.content":
    "In most cases, data coming from different sources cannot be used directly in Tableau as the data may too messy for analysis or way too much data imported into can cause performance issue. In this circumstance, we have to go through a data modeling and transformation process to provide the accurate and exact data set for further analysis. With our expert knowledge in designing data transformation pipeline, we can ensure there isn’t excessive data being imported into the Tableau which causes potential performance issue.",
  "tableau.analytics.title": "Pushing your Tableau Analytics to next level",
  "tableau.analytics.prefix": "ADVANCED ANALYTICS WITH TABLEAU",
  "tableau.analytics.content":
    "Integrate with tools such R, Python or Alteryx Platform, we can help you to open up a much bigger spectrum of Tableau analytic capabilities. In order to find answers for the “Why and How” questions, we will help you to assess the data readiness and then design the solution using advanced analytics such as predictive analysis, segmentation and what if scenarios, etc.",
  // Works Page
  "works.heroText": "Our Works",
  "works.intro.content": "Tailored solution to meet every requirement",
  "works.projectLink.project1": "Tableau Dashboard",
  "works.projectLink.project2": "Realtime Monitoring Platform",
  "works.projectLink.project3": "Housing Portfolio Management Tool",
  "works.projectLink.project4": "Centralized Analytic Platform",
  "works.roles.platforms": "Platforms",
  "works.roles.roles": "Roles",
  "works.roles.deliverables": "Deliverables",
  "works.roles.react": "ReactJS",
  "works.roles.javascript": "Javascript",
  "works.roles.webApp": "Responsive Web Application",
  "works.roles.excelVBA": "Excel VBA",
  "works.roles.dataModel": "Data Model",
  "works.roles.tableau": "Tableau",
  "works.roles.sapHana": "SAP Hana",
  "works.roles.training": "Enterprise Training",
  "works.roles.tableauApplication": "Tableau Application",
  "works.roles.tableauServer": "Tableau Application",
  "works.roles.processEnhancement": "Business Process Enhancement",
  "works.roles.solutionDesign": "Solution Design",
  "works.roles.appDevelopment": "Application Development",
  "works.roles.completeApplication": "Complete Application",
  "works.roles.dataWarehouse": "Data Warehouse",
  // Tableau Dashboard
  "tableauDashboard.heroText": "Tableau Dashboard",
  "tableauDashboard.clientProfile.heading": "Providing service with confidence",
  "tableauDashboard.clientProfile.details":
    "The client is a local top tier professional service company specializing in financial industry in China with 300+ partners and 5,000+ employees. The client was looking for a comprehensive data solution to provide timely and efficient information and analytics support for all the employees. We proposed Tableau as the main tool for this client and helped them to develop an enterprise data analytics application.",
  "tableauDashboard.roles.platforms": "Platforms",
  "tableauDashboard.roles.roles": "Roles",
  "tableauDashboard.roles.deliverables": "Deliverables",
  "tableauDashboard.roles.platforms.tableau": "Tableau",
  "tableauDashboard.roles.platforms.tableauServer": "Tableau Server",
  "tableauDashboard.roles.platforms.sapHana": "SAP Hana",
  "tableauDashboard.roles.roles.solutionDesign": "Solution Design",
  "tableauDashboard.roles.roles.development": "Application Development",
  "tableauDashboard.roles.roles.training": "Enterprise Training",
  "tableauDashboard.roles.deliverables.tableauApplication":
    "Complete Tableau Application",
  "tableauDashboard.roles.deliverables.dataModel": "Data Model",
  "tableauDashboard.projectHighlight.content":
    "From 127 operational reports, 45 Dashboards, 182 hour preparation time and Excel only into ONE Tableau application, Mobile ready.",
  "tableauDashboard.section1.heading":
    "Centralization, Performance and Security",
  "tableauDashboard.section1.details":
    "Our client had used their legacy operational systems (Finance, CRM, talent etc) for over 20 years, and data/information are in silos. Also, the volume of data for the application is huge, for some of the large module of the application, we have to maintain the performance and assure the response time is within 5 to 7 seconds. Another key challenge is, due to the complexity and confidentiality of some of the business, we have to create complex user access mapping and only provide necessary data and information to each person.",
  "tableauDashboard.section2.heading": "From top to bottom",
  "tableauDashboard.section2.details":
    "Because of the uniqueness of the professional service business, we specifically design the application with different level of information, from firm leaders to service line leaders or regional leaders, then to each individual partner, then to project managers, then to consultants and finally to all the supporting employees. All of them are able to use this application to perform their business activities and drive action from it.",
  "tableauDashboard.section3.heading":
    "Fully self-serve and customized analytics",
  "tableauDashboard.section3.details":
    "We treat the different piece of information as one visualization card, different users basing on their own need, can select different visualization display in the dashboard and save it as their own customized view.",
  "tableauDashboard.section4.heading": "Mobile Journey",
  "tableauDashboard.section4.details":
    "This application is mobile compatible, the information is ready whenever you need.",
  // Realtime Monitoring Dashboard
  "realtimeMonitor.heroText": "Realtime Monitoring Platform",
  "realtimeMonitor.clientProfile.heading":
    "Protecting the environment with technology",
  "realtimeMonitor.clientProfile.details":
    "Our client, one of the biggest local environmental preservation companies in China that utilize their expert knowledge in Zoology, Ecology, and Forestry to help protect the endangered species and the environment that these species rely on to continue to live and propagate. Our client was in huge need of an IOT application that can help them to monitor the preserved area in real time.",
  "realtimeMonitor.projectHighlight.content":
    "According to WWF,  the alarming rate of extinction is 100–1,000 times greater than the expected natural rate. ",
  "realtimeMonitor.section1.heading": "Integrate nature with Data, Live",
  "realtimeMonitor.section1.details":
    "Starting from raw data gathered dierectly from the sensors to the realtime dashboard that updates every 5 seconds with live data from the site, we have design and implemented the entire data pipeline along the frontend application it powers that enables the client to monitor every environmental index of the conservation area.",
  "realtimeMonitor.section2.heading": "Analytic Center",
  "realtimeMonitor.section2.details":
    "Data won’t tell you anything unless you talk to them, we built compact analytic center within this application to allow a user to download historical data in any time frame, performance card of each sensor, malfunction prediction, overall trending of the health index of the environment, etc.",
  "realtimeMonitor.section3.heading": "Detecting, counting and predicting",
  "realtimeMonitor.section3.details":
    "In order to understand the behaviors of the targeted species, we have implemented an object detecting AI algorithm for the captured video in order to count the appearances of the targeted species and collect these time-stamped data to enable prediction and further analysis of the environment to see if any deterioration or enhancement.",
  "realtimeMonitor.section4.heading": "Position well to see more",
  "realtimeMonitor.section4.details":
    "The position of the cameras in the field has to be calculated according to angle, lighting, the habit of targeted animals to capture as much area as possible to detect target species.",
  // Housing Porfolio
  "housingPortfolio.heroText": "Housing Portfolio Management Tool",
  "housingPortfolio.clientProfile.heading":
    "Utilize data analytics to make a positive impact",
  "housingPortfolio.clientProfile.details":
    "Our client, a non-profit organization that is committed to ensuring that Ontario residents have access to safe and affordable housing that improves their quality of life. It worked with 1,400+ social housing providers in Ontario to help protect Ontario’s $40 billion housing asset with services specially designed to address social housing business needs. Our client was looking to revamp one of their main applications which helps municipalities across Ontario to manage all the buildings financials and capital works and perform forecast for the future years in order to optimize the portfolio health and minimize the potential risks in the unstable economic environment.",
  "housingPortfolio.roles.platforms": "Platforms",
  "housingPortfolio.roles.roles": "Roles",
  "housingPortfolio.roles.deliverables": "Deliverables",
  "housingPortfolio.roles.platforms.excelVBA": "Excel VBA application",
  "housingPortfolio.roles.platforms.msSQL": "MS SQL Server",
  "housingPortfolio.roles.roles.solutionRedesign": "Solution Redesign",
  "housingPortfolio.roles.roles.development": "Application Development",
  "housingPortfolio.roles.deliverables.application": "Complete Application",
  "housingPortfolio.roles.deliverables.dataModel": "Data Model",
  "housingPortfolio.projectHighlight.content":
    "Average wait time of affordable housing is around 4 years. In some areas of Ontario, the wait time is 7 to 10 years.",
  "housingPortfolio.section1.heading":
    "Compatibility, Scalability, and Standardization",
  "housingPortfolio.section1.details":
    "There are 47 municipalities in Ontario with a lot of different specific conditions and policies changing year over year. More and more data streams and dimensions are required to incorporate into the application. Our client was in an urgent need of a new application that is compatible, scalable, standardized and very easy to use.",
  "housingPortfolio.section2.heading": "Understanding both perspectives",
  "housingPortfolio.section2.details":
    "Not just simply looking at the financial data, but also including the building condition assessment data to have a 360-degree analysis for the current health of each building and the application predicts the future health of each of the building within the portfolio and suggest action for each of them.",
  "housingPortfolio.section3.heading":
    "Interactive Scenario Modelling and responsive recommendation",
  "housingPortfolio.section3.details":
    "In order for the client to come up with different approaches and solutions tailoring to the various scenario, from micro to macro level, the application has a comprehensive scenario modeling module to allow user to perform before-and-after analysis for the portfolio by changing different parameters, such as interest rate, tenant delinquent rate, rent, etc.",
  // Housing Platform
  "housingPlatform.heroText": "Centralized Analytic Platform",
  "housingPlatform.clientProfile.heading":
    "Driving each decision along the process using data analytics",
  "housingPlatform.clientProfile.details":
    "Our client, a federal organization of Canada which serves the community of housing co-operatives across Canada and member organizations that support their operation and development. Two of the main services of this organization are asset management and refinancing, and they were looking for a centralized data and analytic application that can streamline all the main business processes.",
  "housingPlatform.projectHighlight.content":
    "There are over 2,200 non-profit housing co‑ops home with a quarter of a million people live in over 90,000 households across Canada.",
  "housingPlatform.section1.heading": "Connecting the dots using data",
  "housingPlatform.section1.details":
    "One of the biggest challenges of this application is to be able to link every business process together, including a data collection process to net the building condition data for each component of the building, a prediction module for life expectancy of each component, financial information and analysis, a comprehensive financial model to help the user to determine the optimal refinancing timing, etc.",
  "housingPlatform.section2.heading": "Building Health at first sight",
  "housingPlatform.section2.details":
    "The interactive dashboard of the building health display the condition of the building, operating income and the capital expenditure of each building in the program, enabling quick drill down to the area that with a potential issue.",
  "housingPlatform.section3.heading": "Saving more with data",
  "housingPlatform.section3.details":
    "By mining the housing data across the program, the application generates bulk purchasing opportunity in the future, which allow the program to help the participating houses even save more in purchasing the replacing components",
  // Insights Page
  "insights.heroText1": "Insights",
  // About Us Page
  "about.heroText1": "About Us",
  "about.heading": "Breathe in data, breathe out information",
  "about.content":
    "VS-Group is a technology solution provider that helps clients across different industries to address and innovate their unique business problem utilizing data and technology. With a proven record of delivering success and a team of elite practitioners and developers with from 5 to 20+years of experiences, we believe that we can really drive your business to better success.",
  // Careers Page
  "careers.hero": "Come work with us",
  "careers.title": "Currently available positions",
  // Contact Us Page
  "contact.heroText1": "Contact Us",
  "contact.intro.heading":
    "Feel interested yet? Talk to us on How Can We Help you to transform your business.",
  "contact.intro.content": "7030 Woodbine Avenue, Suite 500, Markham, L3R 6G2",
  "contact.contactForm.title": "Contact Us",
  "contact.contactForm.submitButton": "Submit",
  "contact.contactForm.nameField": "Name",
  "contact.contactForm.companyField": "Company",
  "contact.contactForm.emailField": "Email",
  "contact.contactForm.phoneField": "Phone",
  "contact.contactForm.messageField": "Message",
  "contact.contactForm.nonEmptyErrorMessage":
    "Please fill out the required field",
  "contact.contactForm.wrongEmailFormatErrorMessage":
    "Please enter a valid email",
};

export default messages;
