import React, { Component } from 'react';
import autobind from 'react-autobind';
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from 'video-react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '../layout/grid';
import videoPlayerStyles from '../../assets/jss/videoPlayerStyles';
import { setTimeout } from 'timers';

class VideoPlayer extends Component {
  constructor() {
    super();
    autobind(this);
  }

  componentDidMount() {
    // subscribe state change
    this.refs.player.subscribeToStateChange(this.handleStateChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.hoverEffect && this.props.isHovering) {
      this.play();
    } else if (this.props.hoverEffect && prevProps.isHovering && !this.props.isHovering) {
      this.seek(0);
      this.pause();
    }
  }

  handleStateChange(state, prevState) {
    // copy player state to this component's state
    this.setState({
      player: state
    });
  }

  play() {
    this.refs.player.play();
  }

  pause() {
    this.refs.player.pause();
  }

  seek(seconds) {
    this.refs.player.seek(seconds);
  }
  
  render() {
    const { classes, poster, hoverEffect, autoPlay, src, coverText, loop} = this.props;
    const textWrapperClasses = classNames({
      [classes.textWrapper]: true,
      [classes.textWrapperHover]: hoverEffect
    });

    return (
      <Grid container>
        <Grid className={classes.textContentContainer} item xs={12}>
          <Player
            ref="player"
            poster={poster}
            autoPlay={autoPlay}
            loop={loop}
            preload="auto"
            muted
            playsInline
          >
            <source src={src} />
            <ControlBar disableCompletely={true} />
            <LoadingSpinner className={classes.hiddenPlayerComponents} />
            <BigPlayButton className={classes.hiddenPlayerComponents} />
          </Player>
          <Grid className={textWrapperClasses} container justify="center" alignItems="center" fullHeight>
            <Grid item xs={12}>
              {/* <Typography className={classes.videoCoverText} variant="h5" align="center">
                LINK 1
              </Typography> */}
              {coverText}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(videoPlayerStyles)(VideoPlayer);