import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import Grid from '../../../components/layout/grid';
import Typography from '@material-ui/core/Typography';
import SectionContainer from '../../../components/shared/sectionContainer';
import ImageWrapper from '../../../components/shared/imageWrapper';
import Button from '../../../components/button/button';
import dataImage from '../../../assets/images/data.jpg';
import contentSectionStyles from '../../../assets/jss/contentSectionStyles';
import Divider from '../../../components/layout/divider';
import Particles from 'react-particles-js';
import particlesConfig from '../../../assets/particles-js-config';

class OurServices extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="our-services">
        <SectionContainer>
          <Grid container spacing={24} alignItems="stretch">
            <Grid className={classes.textSection} item xs={12} md={6}>
              <Fade left duration={2000}>
                <Grid container className={classes.innerContainer}>
                  <Grid item xs={12}>
                    <Typography className={classes.textPrefix} color="textSecondary" variant="h5" align="left" gutterBottom>
                      <FormattedMessage id="home.ourServices.prefixText"/>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.heading} variant="h3" align="left" gutterBottom>
                      <FormattedMessage id="home.ourServices.heading"/>
                    </Typography>
                  </Grid>
                  <Divider gutters short bold></Divider>
                  <Grid item xs={12}>
                    <Typography className={classes.mainContent} color="textSecondary" variant="h6" align="left" gutterBottom>
                      <FormattedMessage id="home.ourServices.mainContent"/>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.ctaButton} justify="flex-start">
                      <Button color="primary">
                        <Link to="/services/iot" style={{textDecoration: 'none', color: '#FFF'}}>
                          <FormattedMessage id="home.ourServices.ctaButton"/>
                        </Link>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
            <Grid className={classes.imageSection} item xs={12} md={6}>
              <Grid container className={classes.fullHeight} justify="center" alignItems="center">
                <Grid item xs={12} className={classes.fullHeight}>
                  <Fade right duration={2000}>
                      
                    {/* <Grid container justify="center" alignItems="center">
                      <ImageWrapper noMargin src={dataImage}></ImageWrapper>
                    </Grid> */}
                    <Particles className={classes.fullHeight} params={particlesConfig} />
                    
                  </Fade>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default withStyles(contentSectionStyles)(OurServices);
