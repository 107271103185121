import React, { Component } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import homeHeroImage from '../../assets/images/hero-placeholder.jpg';

class Insights extends Component {
  render() {
    return (
      <div>
        <SectionHero heroImage={homeHeroImage} heroText="insights.heroText1"></SectionHero>
      </div>
    );
  }
}

export default Insights;
