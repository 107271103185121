import React, { Component, Fragment } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import GeneralContentSection from '../../components/works/generalContentSection';
import Roles from '../../components/works/roles';
import ProjectHighlight from '../../components/works/projectHighlight';
import housingPlatformHeroImage from '../../assets/images/hero_housing_platform.jpg';
import dashboardInComputerImage from '../../assets/images/centralized_dashboard.jpg';

class HousingPlatform extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#FFF'}}>
        <SectionHero heroImage={housingPlatformHeroImage} heroText="housingPlatform.heroText"></SectionHero>
        <GeneralContentSection
          isHeader
          heading="housingPlatform.clientProfile.heading"
          details="housingPlatform.clientProfile.details"
        />
        <Roles
          platforms={['works.roles.webApp']}
          roles={['works.roles.processEnhancement', 'works.roles.solutionDesign', 'works.roles.appDevelopment']}
          deliverables={['works.roles.completeApplication', 'works.roles.dataWarehouse']}
        />
        <ProjectHighlight
          content="housingPlatform.projectHighlight.content"
          image={dashboardInComputerImage}
        />
        <GeneralContentSection
          white
          heading="housingPlatform.section1.heading"
          details="housingPlatform.section1.details"
        />
        <GeneralContentSection
          white
          vertical
          heading="housingPlatform.section2.heading"
          details="housingPlatform.section2.details"
        />
        <GeneralContentSection
          white
          heading="housingPlatform.section3.heading"
          details="housingPlatform.section3.details"
        />
      </div>
    );
  }
}

export default HousingPlatform;