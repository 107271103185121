const messages = {
  // App Header
  'appHeader.languageButton.en': 'EN',
  'appHeader.languageButton.zh': '中',
  'appHeader.navLink.ourWork': '成功案例',
  'appHeader.navLink.service': '服务项目',
  'appHeader.navLink.insights': '*ZH* Insights',
  'appHeader.navLink.about': '关于我们',
  'appHeader.navLink.contact': '联系我们',
  'appHeader.subNavLink.iot': 'IoT物联网解决方案',
  'appHeader.subNavLink.bi': '商业洞察',
  'appHeader.subNavLink.tableau': 'Tableau解决方案',
  'appHeader.subNavLink.tableauDashboard': 'Tableau仪表盘',
  'appHeader.subNavLink.realtimeMonitor': '实时环境监测分析平台',
  'appHeader.subNavLink.housingPortfolio': '共管房屋组合管理工具',
  'appHeader.subNavLink.housingPlatform': '智能数据平台',
  // App Footer
  'appFooter.companyMessage.heading': 'VS Consulting',
  'appFooter.companyMessage.bodyContent': '我们希望与您携手迈进数据时代',
  'appFooter.companyMessage.footerLink1': '服务项目',
  'appFooter.companyMessage.footerLink2': '成功案例',
  'appFooter.companyMessage.footerLink3': '关于我们',
  'appFooter.companyMessage.footerLink4': '联系我们',
  'appFooter.companyMessage.footerLink5': '工作职位',
  'appFooter.disclosure': '© 2019 VS Consulting. All Rights Reserved.',
  // Home Page
  'home.heroText1': 'VS Consulting',
  'home.heroText2': '数据赋予我们新的生命',
  'home.heroText3': 'VS主页 - 3',
  'home.intro.byAuthor': '- Daniel Keys Moran',
  'home.intro.mainContent': '你可以从数据中提取信息，但你不可能脱离数据而产生信息',
  'home.ourServices.prefixText': '我们的使命',
  'home.ourServices.heading': '数据是我们的基因',
  'home.ourServices.mainContent': '利用基于数据驱动的方法设计并实施行业领先的定制化解决方案，我们旨在帮助客户发现并解决他们独特的业务问题及需求。',
  'home.ourServices.ctaButton': '我们的服务',
  'home.ourWorks.prefixText': '我们的项目',
  'home.ourWorks.heading': '发掘无限可能',
  'home.ourWorks.mainContent': '我们已经成功地为不同行业和领域的客户实现了可观的收益。',
  'home.ourWorks.ctaButton': '我们的成功案例',
  'home.ourWorks.wordCloud.item1': '数据',
  'home.ourWorks.wordCloud.item2': '人工智能',
  'home.ourWorks.wordCloud.item3': '大数据',
  'home.ourWorks.wordCloud.item4': 'Tableau',
  'home.ourWorks.wordCloud.item5': '机器学习',
  'home.ourWorks.wordCloud.item6': '企业培训',
  'home.ourWorks.wordCloud.item7': '商业资讯',
  'home.ourWorks.wordCloud.item8': '数据分析中心',
  'home.ourWorks.wordCloud.item9': '物联网',
  'home.ourWorks.wordCloud.item10': '数据仓库',
  'home.ourWorks.wordCloud.item11': '万维网应用系统',
  'home.ourWorks.wordCloud.item12': '试算表',
  'home.ourWorks.wordCloud.item12': '区块链',
  'home.ourWorks.wordCloud.item13': '数据科学',
  'home.ourWorks.wordCloud.item14': 'Tableau仪表盘',
  'home.ourWorks.wordCloud.item15': '预测性分析',
  'home.ourWorks.wordCloud.item16': '云端方案',
  'home.ourWorks.wordCloud.item17': '数字化转型',
  'home.ourWorkflow.header': '提供端到端的服务',
  'home.ourWorkflow.consultancy.header': '咨询顾问',
  'home.ourWorkflow.consultancy.body': '*ZH* The Filament Strategy team are genuine thought-leaders in the field. We are differentiated by our practical experience of delivering AI centred projects for our clients.',
  'home.ourWorkflow.implementation.header': '执行项目',
  'home.ourWorkflow.implementation.body': '*ZH* The core capability, Filament brings two decades of enterprise application design & build. Design-led & agile, we delight our clients and retain strong relationships.',
  'home.ourWorkflow.education.header': '售后交接',
  'home.ourWorkflow.education.body': '*ZH* Filament have created a suite of educational programmes & resources to develop the in-house capability of our clients, launching them as an AI leader in their industry.',
  'home.ourWorkflow.content': '*ZH* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit',
  // Service Page
  'services.heroText1': '*ZH* Our Services',
  'services.intro.heading': '*ZH* Lorem ipsum dolor sit amet',
  'services.intro.body': '*ZH* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus augue orci, tincidunt ac lobortis vitae, posuere id odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer tristique eros sit amet nisi placerat eleifend. Donec imperdiet mi id orci hendrerit tincidunt fringilla ut leo. Suspendisse sollicitudin interdum dui, ac dictum mauris eleifend vel. Nunc nec lorem accumsan, mattis nulla eget, ullamcorper erat.',
  // IoT Solution Page
  'iot.heroText': 'AI人工智能 & IoT物联网解决方案',
  'iot.intro.title': '未来已来，从现在起为企业规划并逐步建立基于人工智能及物联网的核心竞争优势，着手未来',
  'iot.intro.heading': '基于物联网和人工智能技术，我们能够帮助您的企业加快数字化发展进程，并开辟一系列未知领域的市场拓展新机会。通过物联网互联设备生成的海量数据是建立有效AI模型的不可或缺的完美输入。无限成为可能。',
  'iot.intro.serviceArea1Title': '物联网应用',
  'iot.intro.serviceArea1Content': '通过从现场传感器无缝收集原始数据，可对数据进行实时监控，并实现自动异常报警及并实时数据分析',
  'iot.intro.serviceArea2Title': '人工智能驱动洞察力',
  'iot.intro.serviceArea2Content': '基于有针对性的AI算法，通过从海洋数量的结构化或非结构化数据中提取隐藏的洞察，帮助您的企业更好地了解业务发展状况',
  'iot.intro.serviceArea3Title': '机器人过程自动化',
  'iot.intro.serviceArea3Content': '通过人工智能和机器学习增强技术，机器人可以在认知领域帮助您的企业实现业务流程的自动化',
  'iot.intro.serviceArea4Title': '无限潜力',
  'iot.intro.serviceArea4Content': '人工智能和物联网的潜力超出了我们的想象，我们愿意倾听并了解为您的企业独特的业务问题，并一起规划解决方案。',
  'iot.application.title': '为您的企业量身定制解决方案',
  'iot.application.content': '我们根据您企业的特殊商业环境，量身定制物联网应用战略，并开发及部署适合您的物联网应用程序。通过我们精心设计的物联网数据工作流，您的企业可以实时收集和监控来自传感器的结构化信息，如温度，压力，湿度，空气质量和视频剪辑，照片，语音等非结构化信息，并且基于收集到的信息建立，我们可以利用AI技术建立自动识别模式，检测异并最终转化为可落地的实际业务行为。',
  'iot.application.prefix': '物联网应用',
  'iot.businessInsights.title': '知你所不知',
  'iot.businessInsights.content': '利用人工智能技术，我们可以帮助您的企业深入挖掘核心业务问题，激发获取未曾获取的业务洞察潜力，并通过数据驱动的决策创造新的业务价值，例如，评估客户偏好如何随时间变化，评估客户离开的原因或者更喜欢确定并预测现有产品的未来价值（或优化当前价值）等。',
  'iot.businessInsights.prefix': '人工智能驱动洞察力',
  'iot.automation.title': '更进一步而非取而代之',
  'iot.automation.content': '结合我们对不同行业的深厚知识以及先进的人工智能和机器学习技能，我们能够帮助您的企业提高效率，降低成本并防止日常业务运营中出现人为错误，例如，自动化文件审查利用自动应用程序可以查找并分析文档中的关键信息和条款，从而节省数小时人工工作。利用AI提取关键信息并使用人工来监督过程，相对纯人工操作，能够大大提高处理速度和准确性。',
  'iot.automation.prefix': '机器人过程自动化',
  'iot.possibilities.title': 'AI技术赋予我们无限的可能性',
  'iot.possibilities.prefix': '无限潜力',
  'iot.possibilities.content': '人工智能和物联网的潜力超出了我们的想象。我们愿意聆听并了解您企业面临的独特的业务挑战，并与您一起建立应对方案。',
  // Business Insight Page
  'bi.heroText': '商业洞察',
  'bi.intro.title': '推动业务增长的关键因素不仅在于从数据中获取洞察，更在于将洞察力转化为可执行及操作的行动力',
  'bi.intro.heading': '从数据到洞察力，从洞察力到行动力是驱动业务价值的两个不可或缺的至关因素。我们能够助力您的企业优化数据管理流程，从数据资产中洞察发展业务驱动因素，并且定制可执行可落地的行动方案，从而实现企业战略目标并最大化业务价值。',
  'bi.intro.serviceArea1Title': '企业级商业智能平台',
  'bi.intro.serviceArea1Content': '为企业搭建支撑数据共享基础平台，保证数据可用、易用，从而有效支撑业务操作，运营及管理',
  'bi.intro.serviceArea2Title': '领导仪表盘及业务绩效分析',
  'bi.intro.serviceArea2Content': '助力企业360度绩效管理及组织管理洞察力',
  'bi.intro.serviceArea3Title': '高级分析',
  'bi.intro.serviceArea3Content': '深入发掘业务驱动因素并实现洞察力驱动的业务战略',
  'bi.intro.serviceArea4Title': '预测分析',
  'bi.intro.serviceArea4Content': '掌握对未来市场及业务发展趋势预测的企业，才能变被动为主动，从而确保在竞争中立于不败之地',
  'bi.platform.title': '从商业智能开始，跨步人工智能',
  'bi.platform.prefix': '企业级商业智能平台',
  'bi.platform.content': '搭建企业数据仓库及商业智能平台不仅仅是简单的数据集成及报表展现，更大的挑战在于业务流程整合，数据管理战略及变革管理。众多企业失败的原因在于缺乏对业务变革层面的预期及应对。我们能够帮助您的企业从业务战略出发，制订企业商业智能战略，统一IT对业务支撑目标，从而达成将战略转化为可执行企业战术蓝图。我们的业务及技术专家能为企业提供当前主流工具及平台的实施及支撑，包括但不限于数据库（SAP HANA, Teradata, Oracle, SQL Server, DB2等), ETL(Informatica, DataStage等), 元数据管理, 主数据管理，报表(Cognos, BOBJ, Tableau, PowerBI等).',
  'bi.dashboard.title': '尽在指尖掌控',
  'bi.dashboard.prefix': '领导仪表盘及业务绩效分析',
  'bi.dashboard.content': '领导仪表盘为管理人员和领导者提供快速简便，实时并可视化公司绩效管理平台，并为下一步业务战略提供指导。我们通过帮助您的企业搭建自定义客户定制仪表盘，不仅为企业提供绩效可视化及洞察分析，更是通过透明化绩效管理为企业逐步实现以绩效为导向的企业文化奠定基础。',
  'bi.advanced.title': '看见，发现，行动',
  'bi.advanced.prefix': '高级分析',
  'bi.advanced.content': '高级分析可以帮助您的企业更快，更准确地进行业务洞察分析。通过利用领先技术建立精确的模型，企业能够建立更精准识别业务发展机会，及最大化利润的有效途径 - 或避免未知的风险。高级分析技术包括数据/文本挖掘，模式匹配，机器学习，预测，可视化，语义分析，网络和聚类分析，模拟等',
  'bi.predictive.title': '知现在，不如预未来',
  'bi.predictive.prefix': '预测分析',
  'bi.predictive.content': '预测分析可帮助您的企业对未来发展建立有效预期及最佳应为方案。通过利用包括机器学习和数据挖掘技术在内的技术，企业可以发现隐藏在数据中的行为模式和发展趋势，这些发现对于公司定义短期和长期战略以及帮助满足关键业务需求至关重要。',
  // Tableau Service Page
  'tableau.heroText': 'Tableau解决方案',
  'tableau.intro.title': '基于Tableau,我们会帮助您的企业建立更易用，更友好的数据可视平台',
  'tableau.intro.heading': '无论您的企业目前基于何种IT基础设施架构，我们的Tableau专家都是您值得信任和依赖的合作伙伴，为您的企业搭建定制化Tableau解决方案。基于我们对Tableau套件及相关产品的深入理解，以及我们在多个行业的实施经验，我们致力于为您的企业提供量身定制的Tableau解决方案，从而推动您的企业业务发展更上一个新的台阶。',
  'tableau.intro.serviceArea1Title': '企业级报表及分析平台',
  'tableau.intro.serviceArea1Content': '将分散的信息集中整合提供一站式数据服务',
  'tableau.intro.serviceArea2Title': '仪表盘开发部署',
  'tableau.intro.serviceArea2Content': '高效可视化能为您的企业建立快速业务响应能力',
  'tableau.intro.serviceArea3Title': '数据建模及转换',
  'tableau.intro.serviceArea3Content': '最优化数据工作流推动数据分析优化性能',
  'tableau.intro.serviceArea4Title': '基于Tableau的高级分析',
  'tableau.intro.serviceArea4Content': '借助于Tableau提供的高级数据分析组件勾画企业业务运营完整视图',
  'tableau.reporting.title': '我们为您提供整套解决方案而不仅限于仪表盘',
  'tableau.reporting.prefix': '企业级报表及分析平台',
  'tableau.reporting.content': '我们专注于为企业搭建一体化应用程序数据平台解决方案，该平台包括内置的多项功能，例如用户自助报表，业务记分卡，高级分析模块等，并能够将多个仪表板和组件组合到一个基于Tableau服务器的最优化应用程序。该应用程序可以为企业带来诸多价值，例如为所有业务用户提供及时的信息，消除大量在组织中流动的“黑市”报表，并能够为整个组织提供权威单一数据源。',
  'tableau.dashboard.title': '用仪表盘来讲述你的故事',
  'tableau.dashboard.prefix': '仪表盘开发部署',
  'tableau.dashboard.content': '在可视化领域，Tableau提供了一整套可视化工具，包括地图，仪表，图表，交叉表和向下钻取等。我们认为倾听您的需求，分析并了解支撑您的企业独特业务的流程是为您的企业设计解决方案的重要输入。同时我们会根据以往成功实施经验，竭诚为您的企业利用数据可视化和分析方法设计开发出高效仪表盘对业务进行有效支撑。',
  'tableau.modeling.title': '种瓜得瓜，种豆得豆',
  'tableau.modeling.prefix': '数据建模及转换',
  'tableau.modeling.content': '在以往案例中我们发现，在大多数情况下来自于不同来源的数据，由于其数据质量未能到达要求，或者过多数据源产生的性能问题，难于直接在Tableau中使用。在这种情况下，我们必须帮助客户首先进行数据建模和转换过程，以获取准确和精确的数据集以供进一步分析。借助于我们在设计数据转换工作流方面的专业知识，我们能够避免导入过多无效数据从而导致潜在的性能问题。',
  'tableau.analytics.title': '将Tableau分析能力推进至新的水平',
  'tableau.analytics.prefix': '基于Tableau的高级分析',
  'tableau.analytics.content': '通过与R，Python或Alteryx Platform等工具的集成，我们可以帮助您的企业搭建更为广泛的Tableau分析功能。同时，我们可帮助您的企业评估数据准备完备度，应用高级分析设计解决方案，例如预测分析，细分和假设情景等，从而帮助您的企业快速了解业务运营中的“为什么和怎麽做”。',
  // Works Page
  'works.heroText': '成功案例',
  'works.intro.content': '我们不为做科技而做科技，而是为了保证我们的方案是真正的最合适您',
  'works.projectLink.project1': 'Tableau 仪表盘',
  'works.projectLink.project2': '实时环境监测分析平台',
  'works.projectLink.project3': '共管房屋组合管理工具',
  'works.projectLink.project4': '智能数据平台',
  'works.roles.platforms': '平台',
  'works.roles.roles': '角色',
  'works.roles.deliverables': '交付',
  'works.roles.react': 'ReactJS',
  'works.roles.javascript': 'Javascript',
  'works.roles.excelVBA': 'Excel VBA',
  'works.roles.dataModel': '数据模型',
  'works.roles.tableau': 'Tableau',
  'works.roles.sapHana': 'SAP Hana',
  'works.roles.training': '企业培训',
  'works.roles.tableauApplication': 'Tableau应用程序',
  'works.roles.tableauServer': 'Tableau服务器',
  'works.roles.webApp': '响应式网页应用程序',
  'works.roles.processEnhancement': '业务流程优化',
  'works.roles.solutionDesign': '解决方案设计',
  'works.roles.appDevelopment': '应用开发',
  'works.roles.completeApplication': '完整应用程序',
  'works.roles.dataWarehouse': '数据仓库',
  // Tableau Dashboard
  'tableauDashboard.heroText': 'Tableau 仪表盘',
  'tableauDashboard.clientProfile.heading': '选择源于信任',
  'tableauDashboard.clientProfile.details': '我们的客户是在中国为金融行业提供专业服务的国际前沿咨询公司。该公司在中国业务范围广泛，并拥有300多个合伙人和5,000多名员工。客户的目标是寻找全面的数据解决方案，为公司员工提供及时有效的信息和分析支持。基于对客户业务需求的深刻理解，以及市场产品的匹配分析，我们建议将Tableau作为该客户建立企业数据分析应用能力的主要工具，开发并实施了基于Tableau的整体解决方案。',
  'tableauDashboard.roles.platforms': '平台',
  'tableauDashboard.roles.roles': '*ZH* Roles',
  'tableauDashboard.roles.deliverables': '*ZH* Deliverables',
  'tableauDashboard.roles.platforms.tableau': '*ZH* Tableau',
  'tableauDashboard.roles.platforms.tableauServer': 'Tableau Server',
  'tableauDashboard.roles.platforms.sapHana': 'SAP Hana',
  'tableauDashboard.roles.roles.solutionDesign': '*ZH* Solution Design',
  'tableauDashboard.roles.roles.development': '*ZH* Application Development',
  'tableauDashboard.roles.roles.training': '*ZH* Training',
  'tableauDashboard.roles.deliverables.tableauApplication': '*ZH* Complete Tableau Application',
  'tableauDashboard.roles.deliverables.dataModel': 'Data Model',
  'tableauDashboard.projectHighlight.content': '从仅基于Excel的 127个业务运营报表，45个仪表板，182个小时的处理时间到兼容移动设备的单一Tableau应用程序的解决方案，带来的不仅是效率的提升，更是用户随时、随地尽在掌控的便利性。',
  'tableauDashboard.section1.heading': '集中部署，高性能及可靠性',
  'tableauDashboard.section1.details': '该客户现有传统的业务运营系统（财务，客户关系管理，人力资源等）已使用超过20年，数据/信息孤岛状态日益严重，严重影响了业务运行效率。如何将各孤岛数据进行有效整合是项目中的一大挑战。同时，应用程序的数据量巨大，对于分析平台应用程序的某些模块，我们必须保持性能并确保响应时间在5到7秒之内。另外，由于某些业务的复杂性和机密性，我们必须创建复杂的用户权限管理解决方案，并且只向每个人提供定制的数据和信息。',
  'tableauDashboard.section2.heading': '自顶至下',
  'tableauDashboard.section2.details': '由于专业服务业务具有其独特性，我们根据不用用户角色设计了具有不同信息颗粒度的多层数据访问模式。从公司领导层，到业务线领导层或区域领导层，到每个合伙人，然后到项目经理，再到顾问，最后到所有员工，不同的用户都能够在该数据分析平台上或许所需信息，来支持业务操作，进行决策分析，从而制订下一步业务发展计划。',
  'tableauDashboard.section3.heading': '完全自助式和客户化定制分析',
  'tableauDashboard.section3.details': '我们将不同的信息定义为多个可视化卡片。不同的用户根据自己的需要，可以在仪表板中选择不同的可视化显示，并将其保存为自己的自定义视图。',
  'tableauDashboard.section4.heading': '移动化历程',
  'tableauDashboard.section4.details': '我们为客户搭建的应用平台可兼容移动设备，确保信息随时可用。',
  // Realtime Monitoring Dashboard
  'realtimeMonitor.heroText': '实时环境监测分析平台',
  'realtimeMonitor.clientProfile.heading': '用技术保护环境',
  'realtimeMonitor.clientProfile.details': '我们的客户是中国本地最大的环境保护公司之一，该客户利用其在动物学，生态学和林业方面的专业知识，通过保护濒危物种所依赖的生存环境，来确保这些物种可持续性生存和繁衍。为了能够更智能化监控动物保护区，我们的客户迫切需要利用物联网技术搭建智能监控平台。',
  'realtimeMonitor.projectHighlight.content': '据世界自然基金会称，濒临灭绝的速度比预期的自然率高100-1000倍。',
  'realtimeMonitor.section1.heading': '自然与数据无缝结合',
  'realtimeMonitor.section1.details': '我们为客户设计并开发部署了端到端的整体数据通道解决方案，包括从将传感器的原始加密数据转换为指定格式，并每隔3到5秒将数据传输并加载至云服务端；设计并部署了在云服务端的主数据库以存储传感数据，以及在现场捕获的实时视频和图片。基于高效数据通道，客户能够在中央监控室实时对保护区状况进行监控，同时监控系统能够在发现任何阈值超出预置警戒线时自动向在保护区现场的工作人员发送警报。',
  'realtimeMonitor.section2.heading': '分析中心',
  'realtimeMonitor.section2.details': '数据真正创造价值在于我们与数据的交互，通过对数据的理解和分析才能最大化数据收益。我们为客户在应用程序中建立了高效的分析中心，以允许用户在任何时间范围内下载历史数据，传感器性能监控卡片，故障预测，健康指数的趋势等。',
  'realtimeMonitor.section3.heading': '检测，计数和预测',
  'realtimeMonitor.section3.details': '为了理解目标物种的行为，我们针对视频创建了物体检测人工智能AI算法，从而计算目标物种出现的数量及时间。利用带时间戳的标签数据，客户能够对环境进行进一步的分析和预测，例如环境情况是否有恶化或改善。',
  'realtimeMonitor.section4.heading': '定位精准捕获目标',
  'realtimeMonitor.section4.details': '摄像机在野外的位置必须根据角度，照明，目标动物的习惯来捕捉尽可能多的区域，从而确保探测到目标物种',
  // Housing Portfolio
  'housingPortfolio.heroText': '共管房屋组合管理工具',
  'housingPortfolio.clientProfile.heading': '利用数据分析服务于社会',
  'housingPortfolio.clientProfile.details': '我们的客户是一个非盈利组织，致力于确保安大略省居民能够获得安全且经济实惠的住房，从而改善他们的生活质量。该客户与安大略省1,400多家社会住房供应商合作，通过为社会住房管理提供专业服务，支撑并管理安大略省400亿美元的住房资产。客户选择我们作为战略合作伙伴，对其核心数据支撑和分析应用平台进行改造。该平台用于管理安大略省各市政当局所有共管房屋的财务和基本工程开支，并对未来几年财务绩效进行预测，从而优化投资组合，并最大限度地降低不稳定经济环境下的潜在风险。',
  'housingPortfolio.roles.platforms': '*ZH* Platforms',
  'housingPortfolio.roles.roles': '*ZH* Roles',
  'housingPortfolio.roles.deliverables': '*ZH* Deliverables',
  'housingPortfolio.roles.platforms.excelVBA': '*ZH* Excel VBA application',
  'housingPortfolio.roles.platforms.msSQL': '*ZH* MS SQL Server',
  'housingPortfolio.roles.roles.solutionRedesign': '*ZH* Solution Redesign',
  'housingPortfolio.roles.roles.development': '*ZH* Application Development',
  'housingPortfolio.roles.deliverables.application': '*ZH* Complete Application',
  'housingPortfolio.roles.deliverables.dataModel': '*ZH* Data Model',
  'housingPortfolio.projectHighlight.content': '经济适用房的平均等待时间约为4年。在安大略省的一些地区，等待时间为7至10年',
  'housingPortfolio.section1.heading': '可兼容性，可扩展性及标准化',
  'housingPortfolio.section1.details': '安大略省的47个城市各有其管理独特性，相关政策也会逐年发生变化。客户需要整合越来越多的数据源和分析模型到该整合平台中，因此对具可兼容性，可扩展性，标准化且易于使用的新应用程序的需求日益迫切。',
  'housingPortfolio.section2.heading': '多角度业务探查',
  'housingPortfolio.section2.details': '整体分析模型不仅仅关注财务数据，同时也包含对各房屋组合及建筑物的评估数据，从而支持对每栋建筑健康状况的360度分析，以及对组合中每栋建筑的未来健康状况进行预测，并提供具针对性的可行性建议。',
  'housingPortfolio.section3.heading': '交互式场景建模及互动式建议',
  'housingPortfolio.section3.details': '为了让客户能够针对各种场景定制不同的方法和解决方案，我们搭建的应用包含从微观层面到宏观层面的全场景建模模块，并支持用户通过调整参数（如利率，租户拖欠率，租金等）的方式对房屋组合财务及健康状况进行调整前后分析。',
  // Housing Platform
  'housingPlatform.heroText': '智能数据平台',
  'housingPlatform.clientProfile.heading': '数据分析驱动业务流程决策',
  'housingPlatform.clientProfile.details': '我们的客户是隶属于加拿大政府联邦的一个组织机构，为加拿大各地的住房合作社社区以及支持其运营和发展的下属成员组织提供服务。该客户的两项主要服务是资产管理和再融资。根据业务发展需求该客户急需优化提升业务流程，其中核心问题是支撑业务流程简化的集中数据和分析应用。',
  'housingPlatform.projectHighlight.content': '在加拿大，我们有超过2,200个非营利性住房合作社提供供25万人居住的90,000多个房屋单元。',
  'housingPlatform.section1.heading': '数据驱动业务集成',
  'housingPlatform.section1.details': '项目面临的最大挑战之一是将各个独立的业务流程集成整合，而重中之重是数据收集流程，从而全面掌控建筑物每个组件的建筑条件数据及组件预期寿命预测，财务信息及分析，全局财务模型，以及辅助用户确定最佳再融资时间的智能提示等。',
  'housingPlatform.section2.heading': '建筑物健康度一览无余',
  'housingPlatform.section2.details': '显示建筑健康度的可交互式仪表板能够实时快速的显示各个建筑物的当前运转状况，营业收入和支出，从而使得客户能够快速的探查并定位存在的潜在风险。',
  'housingPlatform.section3.heading': '数据驱动成本优化',
  'housingPlatform.section3.details': '通过对整体住房数据的深度挖掘，我们的应用程序能够识别并集中化未来批量采购机会，从而帮助使用服务的各住房合作社区在采购替换组件时节省更多成本。',
  // Insights Page
  'insights.heroText1': '*ZH* Insights',
  // About Us Page
  'about.heroText1': '关于我们',
  'about.heading': '输入无序数据，产出有效信息',
  'about.content': '作为一家技术解决方案提供商，VS-Group致力于帮助不同行业的客户利用数据和技术解决和创新他们独特的业务挑战。根据以往成功案例，以及我们拥有5至20多年相关行业及领域经验的精英从业者和开发人员团队，我们相信我们能够真正推动您企业的业务迈上一个新的台阶。',
  // Careers Page
	'careers.hero': '加入我们',
	'careers.title': '目前职位',
  // Contact Us Page
  'contact.heroText1': '联系我们',
  'contact.intro.heading': '欢迎与我们联系，让我们共同探讨如何帮助您的企业向数据化进行转变。',
  'contact.intro.content': '142 Willowdale Ave. Suite 304, Toronto, ON M2N 4Y4',
  'contact.contactForm.title': '联系我们',
  'contact.contactForm.submitButton': '提交',
  'contact.contactForm.nameField': '姓名',
  'contact.contactForm.companyField': '公司名称',
  'contact.contactForm.emailField': '电子邮件地址',
  'contact.contactForm.phoneField': '联系电话',
  'contact.contactForm.messageField': '信息',
  'contact.contactForm.nonEmptyErrorMessage': '请填写必须得栏目',
  'contact.contactForm.wrongEmailFormatErrorMessage': '请输入正确格式的email地址'
};

export default messages;