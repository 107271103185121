import React, { Component } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import ServicesIntro from './sections/servicesIntro';
import ServiceTriangle from './sections/serviceTriangle';
import homeHeroImage from '../../assets/images/hero-placeholder.jpg';

class OurServices extends Component {
  render() {
    return (
      <div>
        <SectionHero heroImage={homeHeroImage} heroText="services.heroText1"></SectionHero>
        <ServicesIntro></ServicesIntro>
        <ServiceTriangle></ServiceTriangle>
      </div>
    );
  }
}

export default OurServices;
