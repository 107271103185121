import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { container, wideContainer } from '../../assets/jss/material-kit-react';

const sectionContianerSyle = theme => ({
  containerBase: {
    maxWidth: '1400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '50px',
    paddingBottom: '50px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    }
  },
  sectionContainer: {
    ...container
  },
  wideContainer: {
    maxWidth: 'unset',
    ...wideContainer
  },
  shortContainerTop: {
    paddingTop: '0px'
  },
  shortContainerBottom: {
    paddingBottom: '0px'
  },
  morePadding: {
    paddingTop: '100px',
    paddingBottom: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '25px',
      paddingBottom: '25px',
    }
  },
  alternateSection: {
    backgroundColor: '#f5f5f5'
  },
  primaryBackground: {
    backgroundColor: theme.palette.primary.dark
  },
  whiteBackground: {
    backgroundColor: '#FFF'
  },
  defaultBackground: {
    backgroundColor: '#fafafa'
  },
  zIndex2: {
    zIndex: 2
  }
});

class SectionContainer extends Component {
  render() {
    const { classes, children, className, alternate, wide, defaultBackground, noPadTop, noPadBot, morePadding, primaryBackground, white, zIndex2 } = this.props;
    const classname = classNames({
      className,
      [classes.containerBase]: true,
      [classes.sectionContainer]: !!!wide,
      [classes.wideContainer]: !!wide,
      [classes.shortContainerTop]: !!noPadTop,
      [classes.shortContainerBottom]: !!noPadBot,
      [classes.alternateSection]: !!alternate,
      [classes.primaryBackground]: !!primaryBackground,
      [classes.whiteBackground]: !!white,
      [classes.defaultBackground]: !!defaultBackground,
      [classes.zIndex2]: !!zIndex2,
      [classes.morePadding]: !!morePadding
    });
    return (
      <div className={classname}>
        {children}
      </div>
    );
  }
}

export default withStyles(sectionContianerSyle)(SectionContainer);