import React, { Component } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import withStyles from '@material-ui/core/styles/withStyles';
import Fade from 'react-reveal/Fade';
import withSizes from 'react-sizes';
import Grid from '../../../components/layout/grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../../components/shared/sectionContainer';
import ImageWrapper from '../../../components/shared/imageWrapper';
import consultancyImage from '../../../assets/images/workflow_consultancy_lb.png';
import implementationImage from '../../../assets/images/workflow_implementation_lb.png';
import educationImage from '../../../assets/images/workflow_education_lb.png';
import contentSectionStyles from '../../../assets/jss/contentSectionStyles';

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 600,
});

const WorkflowStep = ({header, body, image, label}) => (
  <Grid container justify="center">
    {/* <Grid item xs={12}>
      <Typography variant="h5">
        <FormattedMessage id={header}></FormattedMessage>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2">
        <FormattedMessage id={body}></FormattedMessage>
      </Typography>
    </Grid> */}
    <Grid item xs={12} style={{textAlign: 'center'}}>
      <ImageWrapper src={image} width="50%"/>
    </Grid>
    <Grid item xs={12} style={{textAlign: 'center'}}>
      <Typography variant="h6" align="center" gutterBottom>
        <FormattedMessage id={label}></FormattedMessage>
      </Typography>
    </Grid>
  </Grid>
);

class OurWorkflow extends Component {

  render() {
    const { isMobile, classes } = this.props;
    return (
      <div className="our-workflow">
        <SectionContainer alternate wide>
          <Grid container spacing={24} justify="center" alignItems="center">
            <Grid item xs={12}>
              <Fade>
                <Typography className={classes.heading} variant="h3" align="center" gutterBottom>
                  <FormattedMessage id="home.ourWorkflow.header"></FormattedMessage>
                </Typography>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Fade top={isMobile} left={!isMobile}>
                {/* <WorkflowStep
                  header="home.ourWorkflow.consultancy.header"
                  body="home.ourWorkflow.consultancy.body"
                /> */}
                <WorkflowStep image={consultancyImage} label="home.ourWorkflow.consultancy.header"/>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Fade top={isMobile} left={!isMobile} delay={500}>
                <Grid container justify="center">
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    {
                      isMobile ? <ArrowDownwardIcon /> : <ArrowForwardIcon />
                    }
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Fade top={isMobile} left={!isMobile} delay={1000}>
                {/* <WorkflowStep
                  header="home.ourWorkflow.implementation.header"
                  body="home.ourWorkflow.implementation.body"
                /> */}
                <WorkflowStep image={implementationImage} label="home.ourWorkflow.implementation.header"/>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Fade top={isMobile} left={!isMobile} delay={1500}>
                <Grid container justify="center">
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    {
                      isMobile ? <ArrowDownwardIcon /> : <ArrowForwardIcon />
                    }
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Fade top={isMobile} left={!isMobile} delay={2000}>
                {/* <WorkflowStep
                  header="home.ourWorkflow.education.header"
                  body="home.ourWorkflow.education.body"
                /> */}
                <WorkflowStep image={educationImage} label="home.ourWorkflow.education.header"/>
              </Fade>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default withSizes(mapSizesToProps)(withStyles(contentSectionStyles)(OurWorkflow));
