import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { withStyles, Link } from "@material-ui/core";
// import Link from '@material-ui/core/Link';
import Grid from "../../components/layout/grid";
import SectionHero from "../../components/sectionHero/sectionHero";
import contactHeroImage from "../../assets/images/hero_contact.jpg";
import SectionContainer from "../../components/shared/sectionContainer";
import contentSectionStyles from "../../assets/jss/contentSectionStyles";
import Divider from "../../components/layout/divider";
import ContactForm from "./sections/contactForm";

const ContactUsIntroComponent = ({ classes }) => (
  <div className="contact-us__intro">
    <Grid
      container
      spacing={24}
      justify="center"
      className={classes.innerContainer}
    >
      <Grid item xs={12}>
        <Typography
          className={classes.heading}
          variant="h5"
          align="center"
          gutterBottom
        >
          <FormattedMessage id="contact.intro.heading" />
        </Typography>
      </Grid>
      <Divider gutters short bold margin="12px"></Divider>
      <Grid item xs={12}>
        <a href="tel:1-437-288-8898" style={{ textDecoration: "none" }}>
          <Typography
            className={classes.heading}
            variant="h5"
            align="center"
            color="primary"
          >
            437-288-8898
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12}>
        <a
          href="mailto:service@vsconsultinggroup.com"
          style={{ textDecoration: "none" }}
        >
          <Typography
            className={classes.heading}
            variant="h5"
            align="center"
            gutterBottom
            color="primary"
          >
            service@vsconsultinggroup.com
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={classes.mainContentNoSpacing}
          color="textSecondary"
          variant="h6"
          align="center"
          gutterBottom
        >
          <FormattedMessage id="contact.intro.content" />
        </Typography>
      </Grid>
    </Grid>
  </div>
);

const ContactUsIntro = withStyles(contentSectionStyles)(
  ContactUsIntroComponent
);

class ContactUs extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="contact-us">
        <SectionHero
          heroImage={contactHeroImage}
          heroText="contact.heroText1"
        ></SectionHero>
        <SectionContainer morePadding>
          <Grid container spacing={40} justify="center">
            <Grid item xs={12}>
              <ContactUsIntro />
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default withStyles(contentSectionStyles)(ContactUs);
