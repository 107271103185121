import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import parallaxStyle from '../../assets/jss/heroStyle';
import VideoPlayer from '../shared/videoPlayer';

class HeroWrapper extends React.Component {
  render() {
    const {
      classes,
      filter,
      className,
      children,
      style,
      image,
      halfHeight,
      shorter,
      small,
      videoMode
    } = this.props;
    const parallaxClasses = classNames({
      [classes.parallax]: true,
      [classes.halfHeight]: halfHeight,
      [classes.shorter]: shorter,
      [classes.filter]: filter,
      [classes.small]: small,
      [className]: className !== undefined
    });
    return (
      videoMode ?
        <VideoPlayer
          autoPlay
          loop
          src="http://vsconsultinggroup.com/assets/lights.mp4"
          coverText={children}
        /> :
        <div
          className={parallaxClasses}
          style={{
            ...style,
            backgroundImage: 'url(' + image + ')',
            ...this.state
          }}
          ref='parallax'
        >
          {children}
        </div>
    );
  }
}

HeroWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string
};

export default withStyles(parallaxStyle)(HeroWrapper);
