import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '../../components/layout/grid';
import SectionContainer from '../../components/shared/sectionContainer';
import ImageWrapper from '../../components/shared/imageWrapper';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';
import Divider from '../../components/layout/divider';

const ProjectHighlight = ({
  classes,
  image,
  content
}) => {
  return (
    <Fragment>
      <ImageWrapper src={image} white/>
      <SectionContainer white>
        <Fade duration={2000}>
          <Grid container spacing={40} alignItems="center">
            <Grid item xs={12}>
              <Typography className={classes.heading} variant="h3" align="center" gutterBottom>
                <FormattedMessage id={content}/>
              </Typography>
            </Grid>
          </Grid>
        </Fade>
        <Divider lighter margin="30px 0px 0px 0px" />
      </SectionContainer>
    </Fragment>
  );
};

export default withStyles(contentSectionStyles)(ProjectHighlight);