import React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '../layout/grid';
import SectionContainer from '../shared/sectionContainer';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';

const GeneralContentSection = ({
  classes,
  white,
  isHeader,
  vertical,
  heading,
  details,
}) => {
  const headingSize = vertical ? 12 : (isHeader ? 6 : 4);
  const detailsSize = vertical ? 12 : (isHeader ? 6 : 8);
  const spacing = vertical ? 24 : 40;
  const headingVariant = isHeader ? 'h3' : 'h4'; 

  return (
    <SectionContainer white={!!white}>
      <Fade duration={2000}>
        <Grid container spacing={spacing} alignItems="center">
          <Grid item xs={12} md={headingSize}>
            <Typography className={classes.headingBig} variant={headingVariant} align="left" gutterBottom>
              <FormattedMessage id={heading}/>
            </Typography>
          </Grid>
          <Grid item xs={12} md={detailsSize}>
            <Typography className={classes.mainContent} color="textSecondary" variant="body1" align="left" gutterBottom>
              <FormattedMessage id={details}/>
            </Typography>
          </Grid>
        </Grid>
      </Fade>
    </SectionContainer> 
  );
};

export default withStyles(contentSectionStyles)(GeneralContentSection);