import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Grid from '../../../components/layout/grid';
import Typogrphy from '@material-ui/core/Typography';
import SectionContainer from '../../../components/shared/sectionContainer';
import ImageWrapper from '../../../components/shared/imageWrapper';
import Button from '../../../components/button/button';
import dataImage from '../../../assets/images/data.jpg';
import contentSectionStyles from '../../../assets/jss/contentSectionStyles';
import Divider from '../../../components/layout/divider';
import WordCloud from '../../../components/wordCloud/wordCloud';
import {injectIntl} from 'react-intl';

class OurWorks extends Component {
  state = {
    words: [
      'home.ourWorks.wordCloud.item1',
      'home.ourWorks.wordCloud.item2',
      'home.ourWorks.wordCloud.item3',
      'home.ourWorks.wordCloud.item4',
      'home.ourWorks.wordCloud.item5',
      'home.ourWorks.wordCloud.item6',
      'home.ourWorks.wordCloud.item7',
      'home.ourWorks.wordCloud.item8',
      'home.ourWorks.wordCloud.item9',
      'home.ourWorks.wordCloud.item10',
      'home.ourWorks.wordCloud.item11',
      'home.ourWorks.wordCloud.item12',
      'home.ourWorks.wordCloud.item13',
      'home.ourWorks.wordCloud.item14',
      'home.ourWorks.wordCloud.item15',
      'home.ourWorks.wordCloud.item16',
      'home.ourWorks.wordCloud.item17',
    ],
    translatedWords: []
  }
  componentDidMount() {
    const { intl } = this.props;
    const { words } = this.state;
    const translatedWords = words.map((word) => {
      return intl.formatMessage({
        id: word
      });
    });
    this.setState({
      translatedWords: translatedWords
    });
  }

  render() {
    const { classes } = this.props;
    const { translatedWords } = this.state;
    return (
      <div className="our-works">
        <SectionContainer>
          <Grid container spacing={24} alignItems="stretch">
            <Grid className={classes.imageSection} item xs={12} md={6} style={{minHeight: '500px'}}>
              {/* <Fade left duration={2000}>
                <Grid container justify="center" alignItems="center">
                  <WordCloud />
                </Grid>
              </Fade> */}
              <Grid container className={classes.fullHeight} justify="center" alignItems="center">
                <Grid item xs={12} className={classes.fullHeight}>
                  <Grid container fullHeight>
                    <WordCloud
                      words={translatedWords}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.textSection} item xs={12} md={6}>
              <Grid container alignItems="center" fullHeight>
                <Grid item xs={12}>
                  <Fade right duration={2000}>
                    <Grid container className={classes.innerContainer} justify="flex-end" alignItems="center">
                      <Grid item xs={12}>
                        <Typogrphy className={classes.textPrefix} color="textSecondary" variant="h5" align="right" gutterBottom>
                          <FormattedMessage id="home.ourWorks.prefixText"/>
                        </Typogrphy>
                      </Grid>
                      <Grid item xs={12}>
                        <Typogrphy className={classes.heading} variant="h3" align="right" gutterBottom>
                          <FormattedMessage id="home.ourWorks.heading"/>
                        </Typogrphy>
                      </Grid>
                      <Divider gutters short bold></Divider>              
                      <Grid item xs={12}>
                        <Typogrphy className={classes.mainContent} color="textSecondary" variant="h6" align="right" gutterBottom>
                          <FormattedMessage id="home.ourWorks.mainContent"/>
                        </Typogrphy>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container className={classes.ctaButton} justify="flex-end">
                          <Button color="primary">
                            <Link to="/works" style={{textDecoration: 'none', color: '#FFF'}}>
                              <FormattedMessage id="home.ourWorks.ctaButton"/>
                            </Link>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fade>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default injectIntl(withStyles(contentSectionStyles)(OurWorks));
