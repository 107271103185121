import React from 'react';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import SectionContainer from '../../components/shared/sectionContainer';
import Grid from '../../components/layout/grid';
import Divider from '../../components/layout/divider';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';

const AIPossibilities = ({ classes }) => {
  return (
    <SectionContainer wide>
      <Grid className={classes.innerContainer} container spacing={24}>
        <Grid item xs={12}>
          <Typography className={classes.textPrefix} color="textSecondary" variant="h5" align="left" gutterBottom>
            <FormattedMessage id="iot.possibilities.prefix"/>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.heading} variant="h3" align="left" gutterBottom>
            <FormattedMessage id="iot.possibilities.title" />
          </Typography>
        </Grid>
        <Divider gutters short bold margin="12px"></Divider>
        <Grid item xs={12}>
          <Typography className={classes.mainContent} color="textSecondary" variant="h6" align="left" gutterBottom>
            <FormattedMessage id="iot.possibilities.content" />
          </Typography>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

export default withStyles(contentSectionStyles)(AIPossibilities);