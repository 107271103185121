import React, { Component } from 'react';
import classNames from 'classnames';
import MuiGrid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const gridStyle = {
  gridContainer: {
    height: '100%'
  },
  gridItem: {
    height: '100%'
  }
};

class Grid extends Component {
  render() {
    const { classes, children, fullHeight, className, ...props } = this.props;
    const classname = classNames(
      className,
      {
        [classes.gridContainer]: props.container && fullHeight,
        [classes.gridItem]: props.item && fullHeight
      }
    );
    return (
      <MuiGrid {...props} className={classname}>
        {children}
      </MuiGrid>
    );
  }
}

export default withStyles(gridStyle)(Grid);