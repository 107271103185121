import React, { Component } from 'react';
import classNames from 'classnames';
import MuiDivider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';

const dividerStyle = {
  root: {
    backgroundColor: 'rgba(0,0,0)'
  },
  gutters: {
    margin: '10px 0px'
  },
  light: {
    backgroundColor: 'rgba(255,255,255,.2)'
  },
  lighter: {
    backgroundColor: 'rgba(0, 0, 0,.2)'
  },
  short: {
    width: '12%'
  },
  bold: {
    height: '3px'
  }
};

class Divider extends Component {
  render() {
    const { classes, className, gutters, light, lighter, short, bold, margin, ...props } = this.props;
    const classname = classNames(
      className,
      {
        [classes.root]: true,
        [classes.light]: light,
        [classes.lighter]: lighter,
        [classes.gutters]: gutters,
        [classes.short]: short,
        [classes.bold]: bold
      }
    );
    const customStyles = {
      ...margin ? {margin: margin} : {}
    };
    return (
      <MuiDivider {...props} className={classname} style={customStyles}/>
    );
  }
}

export default withStyles(dividerStyle)(Divider);