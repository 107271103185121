const typogrpahyStyles = theme => ({
  primaryContrast: {
    color: theme.palette.primary.contrastText
  },
  bold: {
    fontWeight: '500'
  },
  highOpacity: {
    opacity: '0.9'
  }
});

export default typogrpahyStyles;