import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typogrphy from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import { Carousel } from '../../../components/shared/carousel';
import HeroWrapper from '../../../components/hero/heroWrapper';
import SectionContainer from '../../../components/shared/sectionContainer';
import homeHeroImage from '../../../assets/images/hero_iot.jpg';
import typographyStyles from '../../../assets/jss/typographyStyles';

class HomeHero extends Component {

  render() {
    const { classes } = this.props;
    return (
      <HeroWrapper image={homeHeroImage}>
        <SectionContainer>
					<Fade>
						<Typogrphy variant="h1" className={`${classes.primaryContrast} ${classes.bold} ${classes.highOpacity}`} align="center" gutterBottom>
							<FormattedMessage id="home.heroText1" />
						</Typogrphy>
					</Fade>
					<Fade>
						<Typogrphy variant="h3" className={`${classes.primaryContrast} ${classes.bold} ${classes.highOpacity}`} align="center">
							<FormattedMessage id="home.heroText2" />
						</Typogrphy>
					</Fade>
          {/* <Carousel defaultWait={5000}>
            <Fade>
              <Typogrphy variant="h3" color="textSecondary" style={{display: 'inline-block'}}>
                <FormattedMessage id="home.heroText1" />
              </Typogrphy>
            </Fade>
            <Fade>
              <Typogrphy variant="h3" color="textSecondary" style={{display: 'inline-block'}}>
                <FormattedMessage id="home.heroText2" />
              </Typogrphy>
            </Fade>
            <Fade>
              <Typogrphy variant="h3" color="textSecondary" style={{display: 'inline-block'}}>
                <FormattedMessage id="home.heroText3" />
              </Typogrphy>
            </Fade>
          </Carousel> */}
        </SectionContainer>
      </HeroWrapper>
    );
  }
}

export default withStyles(typographyStyles)(HomeHero);
