import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SectionHero from '../../components/sectionHero/sectionHero';
import aboutHeroImage from '../../assets/images/hero_about.jpg';
import SectionContainer from '../../components/shared/sectionContainer';
import Grid from '../../components/layout/grid';
import Divider from '../../components/layout/divider';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';

class AboutUs extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <SectionHero heroImage={aboutHeroImage} heroText="about.heroText1"></SectionHero>
        <SectionContainer morePadding>
          <Grid container spacing={40}>
            <Grid item xs={12}>
              <Typography className={classes.headingBig} variant="h3" align="center" gutterBottom>
                <FormattedMessage id="about.heading"/>
              </Typography>
            </Grid>
            <Divider lighter margin="30px 0px 30px 0px" width="100%" />
            <Grid item xs={12}>
              <Typography className={classes.mainContent} variant="h5" align="center" gutterBottom>
                <FormattedMessage id="about.content"/>
              </Typography>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default withStyles(contentSectionStyles)(AboutUs);
