import React, { Component } from 'react';
import HomeHero from './sections/homeHero';
import Intro from './sections/intro';
import OurWorkflow from './sections/ourWorkflow';
import OurServices from './sections/ourServices';
import OurWorkds from './sections/ourWorks';

class HomePage extends Component {
  render() {
    return (
      <div>
        <HomeHero></HomeHero>
        <Intro></Intro>
        <OurServices></OurServices>
        <OurWorkflow></OurWorkflow>
        <OurWorkds></OurWorkds>
      </div>
    );
  }
}

export default HomePage;
