import React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '../../components/layout/grid';
import SectionContainer from '../../components/shared/sectionContainer';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';

const Roles = ({
  classes,
  platforms,
  roles,
  deliverables
}) => {
  return (
    <SectionContainer noPadTop>
      <Grid container spacing={32}>
        <Grid item xs={6} md={4}>
          <Fade>
            <Grid container direction="column" spacing={16}>
              <Grid item xs={12}>
                <Typography className={classes.headingBig} variant="h6" align="center" gutterBottom>
                  <FormattedMessage id="works.roles.platforms"/>
                </Typography>
              </Grid>
              {
                platforms.map(item => (
                  <Grid item xs={12} key={item}>
                    <Typography className={classes.mainContent} color="textSecondary" variant="body1" align="center" gutterBottom>
                      <FormattedMessage id={item}/>
                    </Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Fade>
        </Grid>
        <Grid item xs={6} md={4}>
          <Fade delay={500}>
            <Grid container direction="column" spacing={16}>
              <Grid item xs={12}>
                <Typography className={classes.headingBig} variant="h6" align="center" gutterBottom>
                  <FormattedMessage id="works.roles.roles"/>
                </Typography>
              </Grid>
              {
                roles.map(item => (
                  <Grid item xs={12} key={item}>
                    <Typography className={classes.mainContent} color="textSecondary" variant="body1" align="center" gutterBottom>
                      <FormattedMessage id={item}/>
                    </Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Fade>
        </Grid>
        <Grid item xs={6} md={4}>
          <Fade delay={1000}>
            <Grid container direction="column" spacing={16}>
              <Grid item xs={12}>
                <Typography className={classes.headingBig} variant="h6" align="center" gutterBottom>
                  <FormattedMessage id="works.roles.deliverables"/>
                </Typography>
              </Grid>
              {
                deliverables.map(item => (
                  <Grid item xs={12} key={item}>
                    <Typography className={classes.mainContent} color="textSecondary" variant="body1" align="center" gutterBottom>
                      <FormattedMessage id={item}/>
                    </Typography>
                  </Grid>
                ))
              }
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    </SectionContainer> 
  );
};

export default withStyles(contentSectionStyles)(Roles);