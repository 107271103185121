import React from 'react';
import makeCarousel from 'react-reveal/makeCarousel';

const containerStyles = {
  position: 'relative',
  overflow: 'hidden',
  height: '150px'
};

const CarouselUI = ({children}) => <div style={containerStyles}>{children}</div>;
const Carousel = makeCarousel(CarouselUI);

export {
  Carousel
};
