/*eslint-disable*/
import React from 'react';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import { Apps } from '@material-ui/icons';

// core components
import CustomDropdown from '../customDropdown/customDropdown.js';
import Button from '../button/button.js';
import headerLinksStyle from '../../assets/jss/headerLinksStyle.js';
import { LocaleConsumer } from '../context/localeContext';

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          // buttonNavLink="/services"
          buttonText={<FormattedMessage id="appHeader.navLink.service" />}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent'
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link className={classes.dropdownLink} to="/services/iot">
              <FormattedMessage id="appHeader.subNavLink.iot" />
            </Link>,
            <Link className={classes.dropdownLink} to="/services/bi">
              <FormattedMessage id="appHeader.subNavLink.bi" />
            </Link>,
            <Link className={classes.dropdownLink} to="/services/tableau">
              <FormattedMessage id="appHeader.subNavLink.tableau" />
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        {/* <Link className={classes.navLink} to="/works">
          <FormattedMessage id="appHeader.navLink.ourWork" />
        </Link> */}
        <CustomDropdown
          noLiPadding
          buttonNavLink="/works"
          buttonText={<FormattedMessage id="appHeader.navLink.ourWork" />}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent'
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link className={classes.dropdownLink} to="/works/tableau-dashboard">
              <FormattedMessage id="appHeader.subNavLink.tableauDashboard" />
            </Link>,
            <Link className={classes.dropdownLink} to="/works/realtime-monitor">
              <FormattedMessage id="appHeader.subNavLink.realtimeMonitor" />
            </Link>,
            <Link className={classes.dropdownLink} to="/works/housing-portfolio-management">
              <FormattedMessage id="appHeader.subNavLink.housingPortfolio" />
            </Link>,
            <Link className={classes.dropdownLink} to="/works/centralized-analytic-platform">
              <FormattedMessage id="appHeader.subNavLink.housingPlatform" />
            </Link>
          ]}
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/insights">
          <FormattedMessage id="appHeader.navLink.insights" />
        </Link>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/about">
          <FormattedMessage id="appHeader.navLink.about" />
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/contact">
          <FormattedMessage id="appHeader.navLink.contact" />
        </Link>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <LocaleConsumer>
          {
            val => (
              <Button
                onClick={val.toggleLanguage}
                color='transparent'
                target='_blank'
                className={classes.navLink}
              >
                <FormattedMessage id={`appHeader.languageButton.${val.nextLocale}`} />
              </Button>
            )
          }
        </LocaleConsumer>
      </ListItem> */}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
