import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SectionContainer from '../../../components/shared/sectionContainer';
import Grid from '../../../components/layout/grid';
import Divider from '../../../components/layout/divider';
import contentSectionStyles from '../../../assets/jss/contentSectionStyles';

const WorksIntro = ({ classes }) => (
  <div className="our-works">
    <SectionContainer>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.heading} variant="h6" align="center" gutterBottom>
            <FormattedMessage id="works.intro.content" />
          </Typography>
        </Grid>
        <Divider gutters short bold></Divider>
      </Grid>
    </SectionContainer>
  </div>
);

export default withStyles(contentSectionStyles)(WorksIntro);