import React, { Component } from 'react';
import ServiceIntro from '../../components/service/serviceIntroWrapper';
import ServiceAreaDetails from '../../components/service/serviceAreaDetailsWrapper';
import SectionHero from '../../components/sectionHero/sectionHero';
import biHeroImage from '../../assets/images/hero_bi.jpeg';
import biImage1 from '../../assets/images/bi_platform.svg';
import biImage2 from '../../assets/images/bi_executive.svg';
import biImage3 from '../../assets/images/bi_advanced_analytics.svg';
import biImage4 from '../../assets/images/bi_predictive.svg';


class BusinessInsight extends Component {
  render() {
    return (
      <div>
        <SectionHero heroImage={biHeroImage} heroText="bi.heroText"></SectionHero>
        <ServiceIntro
          title="bi.intro.title"
          heading="bi.intro.heading"
          serviceArea1Title="bi.intro.serviceArea1Title"
          serviceArea1Content="bi.intro.serviceArea1Content"
          serviceArea2Title="bi.intro.serviceArea2Title"
          serviceArea2Content="bi.intro.serviceArea2Content"
          serviceArea3Title="bi.intro.serviceArea3Title"
          serviceArea3Content="bi.intro.serviceArea3Content"
          serviceArea4Title="bi.intro.serviceArea4Title"
          serviceArea4Content="bi.intro.serviceArea4Content"
        />
        <ServiceAreaDetails
          image={biImage1}
          title="bi.platform.title"
          prefixText="bi.platform.prefix"
          content="bi.platform.content"
        />
        <ServiceAreaDetails
          imageRight
          image={biImage2}
          title="bi.dashboard.title"
          prefixText="bi.dashboard.prefix"
          content="bi.dashboard.content"
        />
        <ServiceAreaDetails
          image={biImage3}
          title="bi.advanced.title"
          prefixText="bi.advanced.prefix"
          content="bi.advanced.content"
        />
        <ServiceAreaDetails
          imageRight
          image={biImage4}
          title="bi.predictive.title"
          prefixText="bi.predictive.prefix"
          content="bi.predictive.content"
        />
      </div>
    );
  }
}

export default BusinessInsight;
