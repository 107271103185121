import React, { Component, Fragment } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import GeneralContentSection from '../../components/works/generalContentSection';
import Roles from '../../components/works/roles';
import ProjectHighlight from '../../components/works/projectHighlight';
import tableauDashboardHeroImage from '../../assets/images/hero_tableau_dashboard.jpeg';
import computerTableauImage from '../../assets/images/computer_tableau.jpg';

class TableauDashboard extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#FFF'}}>
        <SectionHero heroImage={tableauDashboardHeroImage} heroText="tableauDashboard.heroText"></SectionHero>
        <GeneralContentSection
          isHeader
          heading="tableauDashboard.clientProfile.heading"
          details="tableauDashboard.clientProfile.details"
        />
        <Roles
          platforms={['works.roles.tableau', 'works.roles.tableauServer', 'works.roles.sapHana']}
          roles={['works.roles.solutionDesign', 'works.roles.appDevelopment', 'works.roles.training']}
          deliverables={['works.roles.tableauApplication', 'works.roles.dataModel']}
        />
        <ProjectHighlight
          content="tableauDashboard.projectHighlight.content"
          image={computerTableauImage}
        />
        <GeneralContentSection
          white
          heading="tableauDashboard.section1.heading"
          details="tableauDashboard.section1.details"
        />
        <GeneralContentSection
          white
          vertical
          heading="tableauDashboard.section2.heading"
          details="tableauDashboard.section2.details"
        />
        <GeneralContentSection
          white
          heading="tableauDashboard.section3.heading"
          details="tableauDashboard.section3.details"
        />
        <GeneralContentSection
          white
          vertical
          heading="tableauDashboard.section4.heading"
          details="tableauDashboard.section4.details"
        />
      </div>
    );
  }
}

export default TableauDashboard;