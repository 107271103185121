import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Typogrphy from '@material-ui/core/Typography';
import Grid from '../../../components/layout/grid';
import SectionContainer from '../../../components/shared/sectionContainer';

class ServiceTriangle extends Component {

  render() {
    return (
      <div className="our-services__triangle">
        <SectionContainer>
          <Grid container>
            <Grid item xs={12}>
              <Link to="/services/iot">
                <Typogrphy variant="h3" gutterBottom>
                  AI & IoT Solution
                </Typogrphy>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link to="/services/bi">
                <Typogrphy variant="h3" gutterBottom>
                  Business Insight
                </Typogrphy>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link to="/services/tableau">
                <Typogrphy variant="h3" gutterBottom>
                  Tableau Service
                </Typogrphy>
              </Link>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default ServiceTriangle;
