import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import careersHero from "../../../assets/images/hero_careers.jpg";
import SectionHero from "../../../components/sectionHero/sectionHero";
import SectionContainer from "../../../components/shared/sectionContainer";
import Grid from "../../../components/layout/grid";

class InternConsultantEnergy extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <SectionHero
          heroImage={careersHero}
          heroText="careers.hero"
        ></SectionHero>
        <SectionContainer>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Intern Consultant - Data Analytics in Energy Sector (Spring
                Term)
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Toronto, ON
              </Typography>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  margin: "1.5rem 0",
                }}
              ></div>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                About VS-Consulting Group
              </Typography>
              <Typography variant="body1" gutterBottom>
                VS-Consulting Group is a technology solution provider that helps
                clients across different industries to address and innovate
                their unique business problem utilizing data and technology.
                With a proven record of delivering success and a team of elite
                practitioners and developers with from 5 to 20+years of
                experience, VS-Group believes that they can really drive your
                business to better success.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                About Internships At VS-Consulting Group
              </Typography>
              <Typography variant="body1" gutterBottom>
                One of the valuable experiences to our future interns is our
                team’s ability of solution delivering in various kinds of data
                related projects. We are eager to work with interns and coming
                graduates to jumpstart their careers in the data field. Interns
                will get assigned to different projects from various clients
                depending on their skill sets and nature of the projects.
                Although this is an internship, we encourage all interns to work
                hard, ask questions, be dedicated and curious, and enjoy the
                working environment of solution delivery. Lastly, we are always
                welcome students to come back to the company on a full-time
                basis after graduation if there is an opening role.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                Responsibilities
              </Typography>
              <Typography variant="body1" gutterBottom>
                Reporting to your project team lead, the intern will gain
                exposure to different parts of data projects such as requirement
                gathering from clients, data collection, data reporting, data
                modelling, data analysis, dashboard development, etc.
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                <ul>
                  <li>
                    Provides support on data collection from different sources
                  </li>
                  <li>
                    Develops data solutions such as Excel VBA tool, Tableau
                    Dashboard, PowerBI Dashboard and different types of BI and
                    Process Improvement projects, etc. based on a full
                    understanding of the client’s requirement and stakeholder
                    needs
                  </li>
                  <li>
                    Works with consultants to implement new data solutions for
                    the client
                  </li>
                  <li>
                    Assist project manager or business analyst to update project
                    progress tracking document, requirement document, User
                    Acceptance Testing documents, etc.
                  </li>
                  <li>
                    Builds effective relationships with clients and internal
                    development team
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <i>
                  Internship term is flexible and can be for 3 or 4 month term,
                  depending on the candidate.
                </i>
              </Typography>
              <Typography variant="body1" gutterBottom>
                A strong and successful candidate will be one that is
                open-minded, easy-going and has the ability to adapt to a
                fast-paced changing environment with positivity and a sense of
                humour.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                Qualifications
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                <ul>
                  <li>
                    Students currently enrolled in the graduate or
                    post-secondary education, majoring in Finance, Business
                    Analytics, Computer Science, Mathematics, Statistics,
                    Economics, or another related field
                  </li>
                  <li>
                    Strong Excel skills, knowledge of Pivot Table, lookups,
                    Conditional Formula, Power Query, etc.
                  </li>
                  <li>
                    Knowledge in SQL and familiarity with at least one of the
                    database tools such as MS-SQL Server, MySQL, Oracle, etc.
                  </li>
                  <li>
                    Familiarity with Tableau, Power BI or other Business
                    Intelligence software is a plus
                  </li>
                  <li>
                    Excellent verbal and written communication - can communicate
                    with both internal and external partners
                  </li>
                  <li>
                    Accountable and can take ownership of a project or task from
                    beginning to end
                  </li>
                  <li>
                    Excellent planning, troubleshooting, problem-solving and
                    organizational skills
                  </li>
                </ul>
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                How To Apply
              </Typography>
              <Typography variant="body1" gutterBottom>
                We fully appreciate your interest in the VS-Group. But due to
                capacity limits, we will only respond to shortlisted applicants.
                However, we will keep your information on file and contact you
                for future potential opportunities.
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                Seniority Level
              </Typography>
              <Typography variant="body1" gutterBottom>
                Internship
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                Industry and function
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                <ul>
                  <li>Consulting</li>
                  <li>Data and Analytics</li>
                </ul>
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ paddingTop: "1.5rem" }}
              >
                Contact
              </Typography>
              <Typography variant="body1" gutterBottom>
                All applicants please directly submit your resume to{" "}
                <a href="mailto:talent@vsconsultinggroup.com">
                  talent@vsconsultinggroup.com
                </a>
              </Typography>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default InternConsultantEnergy;
