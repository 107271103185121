import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import careersHero from "../../assets/images/hero_careers.jpg";
import SectionHero from "../../components/sectionHero/sectionHero";
import SectionContainer from "../../components/shared/sectionContainer";
import Grid from "../../components/layout/grid";
import Divider from "../../components/layout/divider";

class Careers extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <SectionHero
          heroImage={careersHero}
          heroText="careers.hero"
        ></SectionHero>
        <SectionContainer>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                <FormattedMessage id="careers.title" />
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <List
                component="nav"
                style={{ width: "100%" }}
                aria-label="job posting links"
              >
                <ListItem button>
                  <Link
                    style={{ textDecoration: "none", width: "100%" }}
                    to="/careers/bi"
                  >
                    <Typography variant="subtitle1" component="p">
                      Business Intelligence Analyst
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      Toronto, ON
                    </Typography>
                    <Typography variant="body1">
                      Start date: Immediate
                    </Typography>
                  </Link>
                </ListItem>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    margin: "1.5rem 0",
                  }}
                ></div>
                <ListItem button>
                  <Link
                    style={{ textDecoration: "none", width: "100%" }}
                    to="/careers/intern-consultant-energy"
                  >
                    <Typography variant="subtitle1" component="p">
                      Intern Consultant - Data Analytics in Energy Sector
                      (Spring Term)
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      Toronto, ON
                    </Typography>
                    <Typography variant="body1">
                      Start date: January 2023
                    </Typography>
                  </Link>
                </ListItem>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    margin: "1.5rem 0",
                  }}
                ></div>
                <ListItem button>
                  <Link
                    style={{ textDecoration: "none", width: "100%" }}
                    to="/careers/intern-consultant-public"
                  >
                    <Typography variant="subtitle1" component="p">
                      Intern Consultant - Data Analytics in Public Sector
                      (Spring Term)
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      Toronto, ON
                    </Typography>
                    <Typography variant="body1">
                      Start date: January 2023
                    </Typography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default Careers;
