import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '../../components/layout/grid';
import SectionContainer from '../../components/shared/sectionContainer';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';
import Divider from '../../components/layout/divider';

const ServiceAreaComponent = ({ classes, title, content }) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h6" align="center" gutterBottom>
        <FormattedMessage id={title}/>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography className={classes.mainContent} color="textSecondary" variant="body1" align="center" gutterBottom>
        <FormattedMessage id={content}/>
      </Typography>
    </Grid>
  </Grid>
);

const ServiceArea = withStyles(contentSectionStyles)(ServiceAreaComponent);

const ServiceIntro = ({
  classes,
  title,
  heading,
  serviceArea1Title,
  serviceArea1Content,
  serviceArea2Title,
  serviceArea2Content,
  serviceArea3Title,
  serviceArea3Content,
  serviceArea4Title,
  serviceArea4Content
}) => (
  <div className="consulting__intro">
    <SectionContainer>
      <Grid container spacing={40} className={classes.innerContainer}>
        <Grid item xs={12}>
          <Typography className={classes.heading} variant="h3" align="left" gutterBottom>
            <FormattedMessage id={title}/>
          </Typography>
        </Grid>
        <Divider gutters short bold margin="20px"></Divider>
        <Grid item xs={12}>
          <Typography className={classes.mainContent} color="textSecondary" variant="h6" align="left" gutterBottom>
            <FormattedMessage id={heading}/>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={40}>
            <Grid item xs={12} sm={6}>
              <ServiceArea
                title={serviceArea1Title}
                content={serviceArea1Content}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ServiceArea
                title={serviceArea2Title}
                content={serviceArea2Content}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ServiceArea
                title={serviceArea3Title}
                content={serviceArea3Content}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ServiceArea
                title={serviceArea4Title}
                content={serviceArea4Content}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  </div>
);

export default withStyles(contentSectionStyles)(ServiceIntro);

