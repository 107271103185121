import React, { Component, Fragment } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import GeneralContentSection from '../../components/works/generalContentSection';
import Roles from '../../components/works/roles';
import ProjectHighlight from '../../components/works/projectHighlight';
import realtimeMonitorHeroImage from '../../assets/images/hero_realtime_monitor.jpg';
import mapInComputer from '../../assets/images/mapInComputer.jpg';

class RealtimeMonitor extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#FFF'}}>
        <SectionHero heroImage={realtimeMonitorHeroImage} heroText="realtimeMonitor.heroText"></SectionHero>
        <GeneralContentSection
          isHeader
          heading="realtimeMonitor.clientProfile.heading"
          details="realtimeMonitor.clientProfile.details"
        />
        <Roles
          platforms={['works.roles.webApp']}
          roles={['works.roles.solutionDesign', 'works.roles.appDevelopment']}
          deliverables={['works.roles.completeApplication', 'works.roles.dataWarehouse']}
        />
        <ProjectHighlight
          content="realtimeMonitor.projectHighlight.content"
          image={mapInComputer}
        />
        <GeneralContentSection
          white
          heading="realtimeMonitor.section1.heading"
          details="realtimeMonitor.section1.details"
        />
        <GeneralContentSection
          white
          vertical
          heading="realtimeMonitor.section2.heading"
          details="realtimeMonitor.section2.details"
        />
        <GeneralContentSection
          white
          heading="realtimeMonitor.section3.heading"
          details="realtimeMonitor.section3.details"
        />
        <GeneralContentSection
          white
          vertical
          heading="realtimeMonitor.section4.heading"
          details="realtimeMonitor.section4.details"
        />
      </div>
    );
  }
}

export default RealtimeMonitor;