import React, { Component } from 'react';
import SectionHero from '../../components/sectionHero/sectionHero';
import WorksIntro from './sections/worksIntro';
import WorksLinks from './sections/workLinks';
import worksHeroImage from '../../assets/images/hero_works.jpg';

class OurWorks extends Component {
  render() {
    return (
      <div className="our-works">
        <SectionHero heroImage={worksHeroImage} heroText="works.heroText"></SectionHero>
        <WorksIntro />
        <WorksLinks />
      </div>
    );
  }
}

export default OurWorks;
