const contentSectionStyles = theme => ({
  innerContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  heading: {
    lineHeight: '1.2em',
    fontWeight: '300',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  headingBig: {
    fontWeight: '500',
    lineHeight: '1.2em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  mainContent: {
    fontWeight: '400',
    lineHeight: '1.9',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  mainContentNoSpacing: {
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  textPrefix: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  textSection: {
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  imageSection: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  ctaButton: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  fullHeight: {
    height: '100%'
  },
});

export default contentSectionStyles; 