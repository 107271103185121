import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typogrphy from '@material-ui/core/Typography';
import Grid from '../../../components/layout/grid';
import { FormattedMessage } from 'react-intl';
import SectionContainer from '../../../components/shared/sectionContainer';

class ServicesHero extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div className="our-services__intro">
        <SectionContainer>
          <Grid container>
            <Grid item xs={12}>
              <Typogrphy variant="h3" gutterBottom>
                <FormattedMessage id="services.intro.heading"/>
              </Typogrphy>
            </Grid>
            <Grid item xs={12}>
              <Typogrphy variant="h5" gutterBottom>
                <FormattedMessage id="services.intro.body"/>
              </Typogrphy>
            </Grid>
          </Grid>
        </SectionContainer>
      </div>
    );
  }
}

export default ServicesHero;
