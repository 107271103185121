import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import careersHero from '../../../assets/images/hero_careers.jpg';
import SectionHero from '../../../components/sectionHero/sectionHero';
import SectionContainer from '../../../components/shared/sectionContainer';
import Grid from '../../../components/layout/grid';

class BI extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#FFF'}}>
        <SectionHero heroImage={careersHero} heroText="careers.hero"></SectionHero>
				<SectionContainer>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="h5" gutterBottom>
								Business Intelligence Analyst
							</Typography>
							<Typography variant="subtitle2" gutterBottom>
								Toronto, ON
							</Typography>
							<div style={{width: '100%', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)', margin: '1.5rem 0'}}></div>
							<Typography variant="h6" gutterBottom>
								About VS-Consulting Group
							</Typography>
							<Typography variant="body1" gutterBottom>
								VS-Consulting Group is a technology solution provider that helps clients across different industries to address and innovate their unique business problem utilizing data and technology. With a proven record of delivering success and a team of elite practitioners and developers with from 5 to 20+years of experience, VS-Group believes that they can really drive your business to better success.
							</Typography>
							<Typography variant="h6" gutterBottom style={{paddingTop: '1.5rem'}}>
								Responsibilities
							</Typography>
							<Typography variant="body1"  component="div" gutterBottom>
								<ul>
									<li>
										Helps different types of clients to redesign,  develop, and implement data and analytics types of solutions
									</li>
									<li>
										Builds interactive visualizations to effectively communicate data driven insights to business users
									</li>
									<li>Develops dynamic models that can make business recommendations based on various types of data and full understanding of client’s business strategy</li>
									<li>Supports development and execution of strategic analytics & reporting initiatives in collaboration with internal and external stakeholders</li>
									<li>Develops self-serve analytics by non-analytical end users</li>
									<li>Develops end users training documents to support organizational change management process for the client</li>
									<li>Automates client’s operational reporting process</li>
									<li>Builds effective relationships with internal/external stakeholders</li>
									<li>Collaborates with internal and external stakeholders in order to deliver successfully</li>
								</ul>
							</Typography>
							<Typography variant="h6" gutterBottom style={{paddingTop: '1.5rem'}}>
								Qualifications
							</Typography>
							<Typography variant="body1"  component="div" gutterBottom>
								<ul>
									<li>Minimum Bachelor’s Degree in related fields such as Business Analytics, Business Intelligence, Data Science, Statistics, Finance and Computer Science</li>
									<li>3 to 5 years of experience working with data and analytics types of projects (e.g., Finance, Data Warehousing, Data Analytics, Reporting)</li>
									<li>Experience with one or more tools in the Business Intelligence (e.g., Tableau, PowerBI, QlikView, Cognos, Alteryx, etc.l)</li>
									<li>Expert in Excel for data analysis/reporting, be able to utilize features such as Power Pivot, Power Query, complex Excel Formulas, etc.</li>
									<li>Excellent critical thinking, analytical and problem solving skills in the data analytics domain</li>
									<li>Business acumen in the area of relating data to business process drivers and performance management</li>
									<li>Tailors communication appropriately to both business and IT audiences</li>
									<li>Collaborative team-focused project delivery experience with the ability to work independently</li>
								</ul>
							</Typography>
							<Typography variant="h6" gutterBottom style={{paddingTop: '1.5rem'}}>
								How To Apply
							</Typography>
							<Typography variant="body1" gutterBottom>
								We fully appreciate your interest in the VS-Group. But due to capacity limits, we will only respond to shortlisted applicants. However, we will keep your information on file and contact you for future potential opportunities.
							</Typography>
							<Typography variant="h6" gutterBottom style={{paddingTop: '1.5rem'}}>
								Seniority Level
							</Typography>
							<Typography variant="body1" gutterBottom>
								Mid-Senior
							</Typography>
							<Typography variant="h6" gutterBottom style={{paddingTop: '1.5rem'}}>
								Industry and function
							</Typography>
							<Typography variant="body1" component="div" gutterBottom>
								<ul>
									<li>Consulting</li>
									<li>Data and Analytics</li>
								</ul>
							</Typography>
							<Typography variant="h6" gutterBottom style={{paddingTop: '1.5rem'}}>
								Contact
							</Typography>
							<Typography variant="body1" gutterBottom>
								All applicants please directly submit your resume to <a href="mailto:talent@vsconsultinggroup.com">talent@vsconsultinggroup.com</a>
							</Typography>
						</Grid>
					</Grid>
				</SectionContainer>
      </div>
    );
  }
}

export default BI;