import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '../../components/layout/grid';
import ImageWrapper from '../../components/shared/imageWrapper';
import SectionContainer from '../../components/shared/sectionContainer';
import Divider from '../../components/layout/divider';
import contentSectionStyles from '../../assets/jss/contentSectionStyles';

const ImageSection = ({ classes, image, right }) => (
  <Grid className={classes.imageSection} item xs={12} md={6}>
    <Grid container justify="center" alignItems="center" fullHeight>
      <Fade {...right ? {right: true} : {left: true}} duration={2000}>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <ImageWrapper src={image} width="100%"></ImageWrapper>
        </Grid>
      </Fade>
    </Grid>
  </Grid>
);

const TextSection = ({ classes, title, prefixText, content }) => (
  <Grid className={classes.textSection} item xs={12} md={6}>
    <Fade duration={2000}>
      <Grid container className={classes.innerContainer} spacing={24}>
        <Grid item xs={12}>
          <Typography className={classes.textPrefix} color="textSecondary" variant="h5" align="left" gutterBottom>
            <FormattedMessage id={prefixText}/>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.heading} variant="h3" align="left" gutterBottom>
            <FormattedMessage id={title} />
          </Typography>
        </Grid>
        <Divider gutters short bold margin="12px"></Divider>
        <Grid item xs={12}>
          <Typography className={classes.mainContent} color="textSecondary" variant="h6" align="left" gutterBottom>
            <FormattedMessage id={content}/>
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  </Grid>
);

const ServiceAreaDetails = ({
  classes,
  imageRight,
  title,
  prefixText,
  content,
  image
}) => {
  return (
    <SectionContainer alternate wide>
      <Grid container spacing={40}>
        {
          imageRight ?
            <Fragment>
              <TextSection classes={classes} title={title} content={content} prefixText={prefixText} />
              <ImageSection classes={classes}  image={image} right/>
            </Fragment> :
            <Fragment>
              <ImageSection classes={classes} image={image} />
              <TextSection classes={classes} title={title} content={content} prefixText={prefixText} />
            </Fragment>
        }
      </Grid>
    </SectionContainer>
  );
};

export default withStyles(contentSectionStyles)(ServiceAreaDetails);