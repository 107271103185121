const formStyles = theme => ({
  formTitle: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  textInput: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  fullWidthInput: {
    width: '100%'
  }
});

export default formStyles; 