import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import { LocaleProvider, LocaleConsumer } from './components/context/localeContext';
import Layout from './layout';

addLocaleData([...en, ...zh]);

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#BBDEFB',
      main: '#2196F3',
      dark: '#1976D2',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    text: {
      primary: '#212121',
      secondary: '#546672'
    }
  },
  typography: {
    useNextVariants: true,
  },
});

const { breakpoints, typography: { pxToRem } } = defaultTheme;

const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '5rem',
        [breakpoints.down('xs')]: {
          fontSize: '3rem'
        }
      },
      h3: {
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(24)
        }
      },
      h4: {
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(20)
        }
      },
      h5: {
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(16)
        }
      },
      h6: {
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(16)
        }
      },
      subtitle1: {
        [breakpoints.down('xs')]: {
          fontSize: pxToRem(12)
        }
      }
    }
  }
};

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <LocaleProvider>
            <LocaleConsumer>
              {
                val => (
                  <IntlProvider locale={val.currentLocale} key={val.currentLocale} messages={val.messages}>
                    <Layout />
                  </IntlProvider>
                )
              }
            </LocaleConsumer>
          </LocaleProvider>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
